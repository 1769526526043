import { InputLabel, Typography, Button, Divider } from '@material-ui/core';
import { useForm } from "react-hook-form";
import React, { useCallback, useEffect, useState } from 'react';
import './documentUpload.scss';
import DragDropFile from '../../common/DragDropFile';
import { get, put } from "../../../services/api/baseApi";
import { saveAs } from "file-saver";
import { displayToast } from '../../../store/reducers/uiStatusReducer';
import { useDispatch } from 'react-redux';
import { ErrorOutline } from '@material-ui/icons';

const DocumentsUpload = ({agentView, profile}) => {

    const [initialValues, setInitialValues] = useState({
        studentId: [],
        transcripts: [],
        testResults: [],
        personalStatements: [],
        references: [],
        other: [],
    });

    const { control, handleSubmit, setValue, reset, getValues } = useForm({initialValues});

    const dispatch = useDispatch();

    const uploadDocuments = useCallback(async (data) => {
        try {
            await put(`${process.env.REACT_APP_INSPIRUS_API_URL}/person/${profile.id}/uploads`, data);
            dispatch(displayToast('Documents saved', 'success'));
        } catch(error) {
            console.log(error);
            dispatch(displayToast('Failed to update documents', 'error'));
        } 
    }, [profile, dispatch]);

    useEffect(() => {
        const fetchUploads = async () => {
            try {
                const response = await get(`${process.env.REACT_APP_INSPIRUS_API_URL}/person/${profile.id}/uploads`);
                const body = await response.json();
                if (response.ok) {
                    setInitialValues(body);
                    reset(body);    
                } 
            } catch(error) {
                console.log(error);
            } 
        };
        fetchUploads();
    }, [profile.id, reset]);

    const onSubmit = (data) => {
        uploadDocuments(data)
    };

    const handleDownloadFiles = () => {
        const files = Object.entries(getValues())
            .flatMap(([key, value]) => value)
            .filter(item => item);
        
        files.forEach(file => handleDownloadFile(file));
    }

    const handleDownloadFile = (file) => {
        var byteString = atob(file.data.split(',')[1]);
        var mimeString = file.data.split(',')[0].split(':')[1].split(';')[0]
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
    
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
    
        var blob = new Blob([ab], {type: mimeString});
        saveAs(blob, file.file.path);
      };

    return (
        <div className="documents-upload">
            <Typography variant="h4" className="documents-upload-subtitle bold">Document Upload</Typography>
            {agentView && (
                <Typography className="agent-warning" variant="subtitle"><ErrorOutline className="alert-icon" />Responsibility falls upon you as an agent to manage any downloaded files adhering to GDPR and other regulations.</Typography>
            )}
            <Typography variant="body2">Please upload the relevant documents to complete your profile.</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputLabel>Upload your student ID:</InputLabel>
                <DragDropFile 
                    initialFiles={initialValues.studentId}
                    control={control} 
                    fileFieldKey="studentId"
                    handleSetValue={setValue}
                    />

                <InputLabel>Upload your transcripts:</InputLabel>
                <DragDropFile 
                    initialFiles={initialValues.transcripts}
                    control={control} 
                    fileFieldKey="transcripts"
                    handleSetValue={setValue}
                    />

                <InputLabel>Upload your standardised test results:</InputLabel>
                <DragDropFile 
                    initialFiles={initialValues.testResults}
                    control={control} 
                    fileFieldKey="testResults"
                    handleSetValue={setValue}
                    />

                <InputLabel>Upload your personal statements:</InputLabel>
                <DragDropFile 
                    initialFiles={initialValues.personalStatements}
                    control={control} 
                    fileFieldKey="personalStatements"
                    handleSetValue={setValue}
                    />

                <InputLabel>References:</InputLabel>
                <DragDropFile 
                    initialFiles={initialValues.references}
                    control={control} 
                    fileFieldKey="references"
                    handleSetValue={setValue}
                    />

                <InputLabel>Other:</InputLabel>
                <DragDropFile 
                    initialFiles={initialValues.other}
                    control={control} 
                    fileFieldKey="other"
                    handleSetValue={setValue}
                    />

                <br />

                <div className="button-wrapper">
                    <Button onClick={handleDownloadFiles} type="button" color="primary" style={{backgroundColor: '#337347'}} variant="contained" fullWidth>Download all</Button>
                </div>

                <Divider />
                <div className="button-wrapper">
                    <Button type="submit" color="primary" variant="contained" fullWidth>Save</Button>
                </div>
            </form>
        </div>
    );
}

export default DocumentsUpload;
