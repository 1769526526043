import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { store } from './store/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App';
import {Helmet} from "react-helmet";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import './cookies';

// const tokenLogger = (tokens) => {
//   return tokens;
// }

const theme = {
  typography: {
    "fontFamily": `Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol`,
   },
  palette: {
    primary: {
      main: '#4eadf2',
      contrastText: "#fff"
    },
    secondary: {
      main: '#e5e5e5',
      contrastText: '#000'
    },
    tertiary: {
      main: '#012D6C',
    }
  },
  overrides: {
    MuiTab: {
      wrapper: {
        textTransform: "capitalize",
        fontWeight: "bold",
        width: "fit-content",
      },
      root: {
        minWidth: "!60px",
        justifyContent: 'flex-start',
        paddingBottom: '8px',
        minHeight: 0,
        borderBottom: '1px solid #dbdbdb',
      },
    },
    MuiTabs: {
      root: {
        border: '1px solid #dbdbdb',
        borderBottom: 'none',
        borderRadius: '4px',
      },
      indicator: {
        backgroundColor: '#012D6C',
        width: '4px',
        borderRadius: '4px',
        left: '0px'
      }
    },
    MuiButton: {
      root: {
        textTransform: "capitalize",
        fontWeight: "bold",
        borderRadius: '10px',
        backgroundColor: "#fff",
      },
      containedSecondary: {
        // border: "1px solid #000",
        fontWeight: "400",
        boxShadow: "none",
      },
      outlined: {
        borderRadius: '10px',
        fontWeight: 'normal'
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '10px',
        backgroundColor: '#fff',
      },
      input: {
        padding: '12px 16px'
      }
    },
    MuiFormLabel: {
      root: {
        color: '#000',
        fontSize: '0.8em',
        fontWeight: 'bold',
        padding: '16px 0'
      }
    },
    MuiDivider: {
      root: {
        margin: '32px 0'
      }
    },
    MuiDropzoneArea: {
      root: {
        minHeight: 'auto',
        borderColor: '#4eadf2',
      },
      text: {
        fontSize: '0.8em',
        fontWeight: 'bold',
        marginTop: '8px'
      },
      icon: {
        width: '30px',
        marginTop: '8px'
      },
      textContainer: {
        flexDirection: 'column-reverse',
        display: 'flex',
        alignItems: 'center'
      }
    },
    MuiDataGrid: {
      root: {
        border: 'unset',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '24px'
      }
    }
  }
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={createTheme(theme)}>
          <Helmet>

          </Helmet>
          <div className="main">
            {/* <div className="main-content"> */}
              <App />
            {/* </div> */}
          </div>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
