import { Button, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import './student-snapshot.scss';
import DefaultImage from '../../../assets/default-profile.svg';
import linkedInLogo from '../../../assets/social-li.svg';
import youtubeLogo from '../../../assets/social-yt.svg';
import twitterLogo from '../../../assets/social-tw.svg';
import facebookLogo from '../../../assets/social-fb.svg';
import AssociationContext from '../../../context/Association';
import moment from 'moment';

const StudentSnapshot = () => {
    const history = useHistory();
    const { association: {child} } = useContext(AssociationContext);

    return (
        <div className="snapshot-container page-content">
            <div className="go-back" onClick={() => history.goBack()}>
                <ArrowBack/>
                <Typography variant="body2">Back to Dashboard</Typography>
            </div>
            <div className="snapshot-wrapper">
                <div className="snapshot">
                    <div className="snapshot-header">
                        <div className="user-avatar-wrapper">
                            <div className="user-avatar">
                                {(
                                    <img src={ child?.avatar || DefaultImage} accept="image/*" alt="Profile" />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="snapshot-content">
                        <Button color="primary" variant="contained" onClick={() => history.push("/editStudent")}>Edit Profile</Button>   
                        <Typography variant="h5">{child?.givenName} {child?.familyName}</Typography>
                        <Typography variant="body2">Student &#8226; {moment().diff(child?.birthDate, 'years')} years old.</Typography>
                        <Typography variant="body2">Nationality &#8226; {child?.countryOfResidence?.value}</Typography>

                        <br />

                        <Typography variant="subtitle1" className="inspire-title">GPA results?</Typography>
                        <Typography variant="body2">{child?.grades}</Typography>

                        <br />

                        <Typography variant="subtitle1" className="inspire-title">What inspires me?</Typography>
                        <Typography variant="body2">{child?.studentBio}</Typography>

                        <br />

                        <Typography variant="subtitle1" className="inspire-title">Interested in studying?</Typography>
                        <Typography variant="body2">{child?.studyInfo}</Typography>

                        <div className="social">
                            {child?.linkedIn && (
                                <a href={`//${child.linkedIn}`} rel="noreferrer" target="_blank"><img src={linkedInLogo} alt="Social LinkedIn" /></a>
                            )}
                            {child?.instagram && (
                                <a href={`//${child.instagram}`} rel="noreferrer"target="_blank"><img src={youtubeLogo} alt="Social Youtube" /></a>
                            )}
                            {child?.facebook && (
                                <a href={`//${child.facebook}`} rel="noreferrer" target="_blank"><img src={facebookLogo} alt="Social Facebook" /></a>
                            )}
                            {child?.twitter && (
                                <a href={`//${child.twitter}`} rel="noreferrer" target="_blank"><img src={twitterLogo} alt="Social Twitter" /></a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StudentSnapshot;
