export const keycloakConfig = {
  realm: "inspirus",
  url: "https://auth.inspirus.io/auth/",
  tokenEndpoint: "https://auth.inspirus.io/auth/realms/inspirus/protocol/openid-connect/token",
  "ssl-required": "external",
  "public-client": true,
  "confidential-port": 0,
  clientId: "inspirus",
  onLoad: "check-sso",
  loginRedirectUri: "",
  registerRedirectUri: "",
};

export default keycloakConfig;
  