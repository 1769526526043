import { CircularProgress, Box } from "@material-ui/core";
import React from "react";
import './gauge.scss';

const Gauge = ({ value, label }) => (
    <div className="gauge">
        <Box sx={{ position: 'relative' }}>
            <CircularProgress
                variant="determinate"
                size={112}
                thickness={4}
                value={100}
                style={{color:"#C0C0C0"}}
            />
            <CircularProgress
                variant="determinate"
                style={{
                    position: 'absolute',
                    left: 0,
                    color: '#012D6C',
                }}
                size={112}
                thickness={4}
                value={value}
            />
            <div className="gauge-label">
                {label}
            </div>
        </Box>
    </div>
);

export default Gauge;
