import React from 'react';
import '../registration.scss';

import Profile from '../../Student/Profile';

const StudentRegistration = ({handleMoveToPreviousStep, keycloakData, fetchUser}) => {
    // const [personDetails, setPersonDetails] = useState({
    //     givenName: '',
    //     familyName: '',
    //     type: 'student',
    //     countryOfResidence: '',
    //     qualifications: [],
    // });
    // const [toastOpen, setToastOpen] = useState();
    // const [selectedEducation, setSelectedEducation] = useState();
    // const [toastMessage, setToastMessage] = useState();
    // const history = useHistory();

    // const [open, setOpen] = useState(false);

    // const handleOpen = () => {
    //     if (!selectedEducation) {
    //         setSelectedEducation();
    //     }
    //     setOpen(true);
    // };

    // const handleCloseModal = () => {
    //     setOpen(false);
    // };

    // const handleOpenToast = (open, message) => {
    //     setToastMessage(message);
    //     setToastOpen(open);
    // };

    // const createStudent = useCallback(async (data) => {
    //     try {
    //         await post(`${process.env.REACT_APP_INSPIRUS_API_URL}/person`, {
    //                 givenName: data.givenName,
    //                 familyName: data.familyName,
    //                 countryOfResidence: data.countryOfResidence,
    //                 qualifications: personDetails.qualifications,
    //                 additionalType: [
    //                     "STUDENT"
    //                 ]
    //             },
    //         );
    //         handleOpenToast(true, "Student sucessfully created");
    //         fetchUser();
    //         history.push('/');
    //     } catch(error) {
    //         console.log(error);
    //         handleOpenToast(true, "Error creating Student");
    //     } 
    // }, [personDetails.qualifications, history, fetchUser]);

//   const { control, handleSubmit } = useForm();

//   const onSubmit = (data) => { 
//     setPersonDetails({...personDetails, data});
//     createStudent(data);
//   };

//   const handleEditEducationClick = (index) => {
//     setSelectedEducation(index);
//     setOpen(true);
//   }

  return (
    <div className="registration-step step2">
        <Profile fetchUser={fetchUser} />
        {/* <div className="registration-content">
            <Typography variant="h5" className="registration-subtitle">Hello prospective student! Please fill in your details below.</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h6">Personal Details</Typography>
                <InputLabel>Firstname*</InputLabel>
                <Controller
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                        <TextField variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                    }
                    name="givenName"
                    control={control}
                    defaultValue=""
                    className="materialUIInput"
                    rules={{ 
                        required: "Firstname is required",
                        minLength: {
                            value: 2,
                            message: "Firstname must be atleast 3 characters."
                        }
                    }}
                />
                <InputLabel>Lastname*</InputLabel>
                <Controller
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                        <TextField variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
                    }
                    name="familyName"
                    control={control}
                    defaultValue=""
                    className="materialUIInput"
                    rules={{ 
                        required: "Lastname is required",
                        minLength: {
                            value: 2,
                            message: "Lastname must be atleast 2 characters."
                        }
                    }}
                />
                <InputLabel>Email Address*</InputLabel>
                <Controller
                    render={({ field }) => <TextField disabled variant="outlined" {...field} />}
                    name="emailAddress"
                    control={control}
                    defaultValue={keycloakData.username}
                />
                <InputLabel>Country of residence</InputLabel>
                <Controller
                    name="countryOfResidence"
                    render={({ field }) => (
                    <Select
                        {...field}
                        options={[
                        { value: "countryA", label: "Country A" },
                        { value: "countryB", label: "Country B" },
                        { value: "countryC", label: "Country C" }
                        ]}
                        className="dropdown"
                    />
                    )}
                    control={control}
                    defaultValue=""
                />
                <Divider />
                <h3>Education</h3>
                {personDetails?.qualifications.map((qualification, index) => (
                    <div className="education-summary" key={qualification.school}>
                    <div className="button-wrapper">
                        <h5 className="education-title">{qualification.school}</h5>
                        <IconButton size="small" aria-label="close" onClick={() => handleEditEducationClick(index)}>
                            <Edit fontSize="small" />
                        </IconButton>
                    </div>
                    <>
                        <Typography variant="caption" className="education-item">
                            {qualification.degree}
                        </Typography>
                    </>
                    <>
                        <Typography variant="caption" className="education-item">
                            {qualification.fieldOfStudy}
                        </Typography>
                    </>
                    <>
                        <Typography variant="caption" className="education-item">
                            {`${moment(qualification.startDate).format('MMMM YYYY')} - ${qualification.studyingHere ? 'Present' : moment(qualification.endDate).format('MMMM YYYY')}`}
                        </Typography>
                    </>
                    <Divider className="education-item-divider" />
                </div>
                ))}
                <Button color="secondary" variant="contained" onClick={handleOpen}>Add another qualification</Button>
                <Divider />
                <div className="button-wrapper">
                    <Button color="secondary" variant="contained" onClick={() => handleMoveToPreviousStep(2)}>Cancel</Button>
                    <Button type="submit" color="primary" variant="contained">Save and continue</Button>
                </div>
            </form>
        </div>
        <EducationModal
            open={open}
            handleClose={handleCloseModal}
            handleSetEducation={setPersonDetails}
            personDetails={personDetails}
            selectedEducation={selectedEducation}
            setSelectedEducation={setSelectedEducation}
        />
        <Toast message={toastMessage} isOpen={toastOpen} handleClose={() => setToastOpen(false)} /> */}
    </div>
  )};

export default StudentRegistration;
