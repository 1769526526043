import { Typography, Chip, Checkbox, Button, Divider } from "@material-ui/core";
import { Cancel, CheckCircle } from "@material-ui/icons";
import React from "react";
import { useState } from "react";
import Gauge from "../../common/Gauge";
import './dashboard.scss';
import { put } from "../../../services/api/baseApi";
import { useDispatch } from 'react-redux';
import { displayToast } from '../../../store/reducers/uiStatusReducer';

const Dashboard = ({agentView, association}) => {
    let {profileCompleted, idVerified, academicTranscripts, documentsVerified} = association;

    const [isProfileCompleted, setIsProfileCompleted] = useState(profileCompleted);
    const [isIdVerified, setIsIdVerified] = useState(idVerified);
    const [isAcademicTranscripts, setIsAcademicTranscripts] = useState(academicTranscripts);
    const [isDocumentsVerified, setIsDocumentsVerified] = useState(documentsVerified);

    const dispatch = useDispatch();

    const isCompleted = (isCompleted) => {
        return isCompleted ? <CheckCircle className="primary" /> : <Cancel className="secondary" style={{color:"#C0C0C0"}}/>;
    }

    const numberSelected = [isProfileCompleted, isIdVerified, isAcademicTranscripts, isDocumentsVerified].filter((value) => value === true).length;

    const updateAssociation = async () => {
        const response = await put(`${process.env.REACT_APP_INSPIRUS_API_URL}/association/${association.id}`,
            {
                profileCompleted: isProfileCompleted,
                idVerified: isIdVerified,
                academicTranscripts: isAcademicTranscripts,
                documentsVerified: isDocumentsVerified,
            },
        );

        if(response.ok) {
            dispatch(displayToast('Association updated successfully', 'success'));
        } else {
            dispatch(displayToast('An error ocurred whilst creating student', 'error'));
        }
    }

    return (
        <div className="dashboard">
            <Typography variant="h4" className="registration-subtitle bold">Dashboard</Typography>
            <Typography variant="body2">Here's your to-do list and progress statistics for your profile, your agent will review what you've done and verify it's correct. This is shown under the agent progress chart.</Typography>
            <div className="dashboard-progress">
                <Gauge
                    value={numberSelected * 25}
                    label={
                        <>
                            <Typography variant="h6">{numberSelected} of 4</Typography>
                        </>
                    }
                />
            </div>
            <div className="dashboard-items">
                <div>
                    <Chip icon={agentView 
                        ? <Checkbox checked={isProfileCompleted} color="primary" className="primary" onClick={() => setIsProfileCompleted(!isProfileCompleted)}/> 
                        : isCompleted(isProfileCompleted)} 
                        label="Your profile has been completed." 
                        variant="outlined" 
                        className="dashboard-chip" 
                        />
                    <Chip icon={agentView 
                        ? <Checkbox checked={isIdVerified} color="primary" className="primary" onClick={() => setIsIdVerified(!isIdVerified)}/> 
                        : isCompleted(idVerified)} 
                        label="Your identity has been verified." 
                        variant="outlined" 
                        className="dashboard-chip" 
                        />
                    <Chip icon={agentView 
                        ? <Checkbox checked={isAcademicTranscripts} color="primary" className="primary" onClick={() => setIsAcademicTranscripts(!isAcademicTranscripts)}/> 
                        : isCompleted(academicTranscripts)} 
                        label="Your academic transcripts have been uploaded." 
                        variant="outlined" 
                        className="dashboard-chip" 
                        />
                    <Chip icon={agentView 
                        ? <Checkbox checked={isDocumentsVerified} color="primary" className="primary" onClick={() => setIsDocumentsVerified(!isDocumentsVerified)}/> 
                        : isCompleted(documentsVerified)} 
                        label="Your documents have been uploaded and verified." 
                        variant="outlined" 
                        className="dashboard-chip" 
                        />
                </div>
            </div>
            {agentView &&
                <>
                    <Divider />
                    <div className="button-wrapper">
                        <Button type="button" color="primary" variant="contained" onClick={() => updateAssociation()} fullWidth>Save profile status</Button>
                    </div>
                </>
            }
            {/* <Chip icon={<CheckBox className="primary"/>} label={<Typography variant="caption">Do you want to recieve email reminders about your progress?</Typography>} variant="outlined" className="dashboard-chip reminder" /> */}
        </div>
    )
};

export default Dashboard;
