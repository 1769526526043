import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';

const Toast = ({message, isOpen, handleClose, severity}) => {
  const anchorOrigin = {
    vertical: severity === 'error' ? 'top' : 'bottom',
    horizontal: severity === 'error' ? 'center' : 'left'
  }
  return (
    <>
      <Snackbar
        anchorOrigin={anchorOrigin}
        open={isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        action={
          <>
            <IconButton size="small" aria-label="close" color="secondary" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      >
        <Alert severity={severity} variant="filled" onClose={handleClose}>{message}</Alert>
      </Snackbar>
    </>
  );
}

export default Toast;
