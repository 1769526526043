import React, { useState } from "react";

const AssociationContext = React.createContext();

const defaultSettings = {
};

export const AssociationProvider = ({ children, association, application }) => {
  const [currentAssociation, setCurrentAssociation] = useState(
    association || defaultSettings
  );

  const [currentApplication, setCurrentApplication] = useState(
    application || defaultSettings
  );

  const saveAssociation = (values) => {
    setCurrentAssociation(values)
  };

  const saveApplication = (values) => {
    setCurrentApplication(values)
  };

  return (
    <AssociationContext.Provider
      value={{ association: currentAssociation, application: currentApplication, saveAssociation, saveApplication }}
    >
      {children}
    </AssociationContext.Provider>
  );
};

export const AssociationConsumer = AssociationContext.Consumer;

export default AssociationContext;