import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Typography, Button, Menu, MenuItem } from '@material-ui/core';
import { ArrowBack, ExpandMore } from '@material-ui/icons';
import DefaultImage from '../../../assets/default-uni.svg';
import './manage-application.scss';
import DataTable from '../../common/DataTable';
import { withStyles } from '@material-ui/styles';
import { get, remove } from "../../../services/api/baseApi";
import { useHistory } from 'react-router';
import AssociationContext from '../../../context/Association';

const styles = {
    error: {
        backgroundColor: '#F55D4F',
        color: "#fff"
    }
};

const ManageApplication = ({classes, setApplication}) => {
    const [applicationData, setApplicationData] = useState([]);
    const history = useHistory();
    const { association } = useContext(AssociationContext);
    const [shouldFetchApplications, setShouldFetchApplications] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const actionMenuOpen = Boolean(anchorEl);
    const [selectedRow, setSelectedRow] = useState();

    const handleOpenActionMenu = (event, row) => {
        setSelectedRow(row);
        setAnchorEl(event.currentTarget);
    };
    const handleCloseActionMenu = () => {
        setSelectedRow();
        setAnchorEl(null);
    };

    const getApplications = useCallback(() => {
        const fetchUploads = async () => {
            try {
                const response = await get(`${process.env.REACT_APP_INSPIRUS_API_URL}/association/${association.id}`);
                const body = await response.json();
                if (response.ok) {
                    setApplicationData(body?.applications);
                } 
            } catch(error) {
                console.log(error);
            } 
        };
        if (association.id) fetchUploads();
    }, [association.id]);

    useEffect(() => {
        if (shouldFetchApplications) {
            getApplications();
            setShouldFetchApplications(false);
        }
    }, [getApplications, shouldFetchApplications]);
    
    const handleDeleteApplication = async (applicationId) => {
        try {
            const response = await remove(`${process.env.REACT_APP_INSPIRUS_API_URL}/application/${applicationId}`);
            if (response.ok) {
                getApplications();
            } 
        } catch(error) {
            console.log(error);
        }
        handleCloseActionMenu();
    }

    const handleOpenStudentProgress = (application) => {
        if (applicationData?.length > 0) {
            setApplication(application);
        }
        history.push('/progress');
    }

    const columns = [
        {
          field: 'logo',
          headerName: ' ',
          width: 80,
          renderCell: (params) => (
              <div className="user-avatar-wrapper">
                <div className="user-avatar">
                    {(
                        <img src={params?.row?.universityLogoSrc || DefaultImage} className={params?.row?.universityLogoSrc ? '' : 'default-image'} accept="image/*" alt="Profile" />
                    )}
                </div>
              </div>
          ),
          sortable: false,
          disableColumnMenu: true
        },
        {
            field: 'universityName',
            headerName: 'University',
            sortable: true,
            flex: 1,
            valueGetter: (params) => params?.row?.universityName, 
        },
        {
            field: 'courseName',
            headerName: 'Course',
            sortable: true,
            flex: 1,
            valueGetter: (params) => params?.row?.courseName, 
        },
        {
            field: 'courseLevel',
            headerName: 'Level',
            sortable: true,
            flex: 1,
            valueGetter: (params) => params?.row?.courseLevel, 
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            valueGetter: (params) => params?.row?.status, 
        },
        {
            field: 'actions',
            headerName: ' ',
            sortable: false,
            flex: 1.5,
            renderCell: (params) => (
                <div className="actions-button-container">
                    <Button type="button" color="primary" variant="contained" onClick={() => handleOpenStudentProgress(params?.row)}>Progress</Button>
                    <Button type="button" className={classes.error} disabled={params?.row?.sendApplicationEmail} onClick={() => handleDeleteApplication(params?.row?.id)}>Delete</Button>
                </div>
            ),
        },
      ];

      const mobileColumns = [
        {
          field: 'logo',
          headerName: ' ',
          width: 80,
          renderCell: (params) => (
              <div className="user-avatar-wrapper">
                <div className="user-avatar">
                    {(
                        <img src={params?.row?.universityLogoSrc || DefaultImage} accept="image/*" alt="Profile" />
                    )}
                </div>
              </div>
          ),
          sortable: false,
          disableColumnMenu: true
        },
        {
            field: 'info',
            headerName: ' ',
            sortable: false,
            flex: 2,
            renderCell: (params) => (
                <div>
                    <div className="sm-table-label">
                        <Typography variant="caption">{params?.row?.applicationRef}</Typography>
                        <Typography variant="caption">{params?.row?.status}</Typography>
                    </div>
                    <Typography variant="body1">{params?.row?.universityName}</Typography>
                    <Typography style={{fontSize: '12px'}}>{params?.row?.courseName}</Typography>
                    <Typography style={{fontSize: '12px'}}>{params?.row?.courseLevel}</Typography>
                </div>
            ),
        },
        {
            field: 'actions',
            headerName: ' ',
            sortable: false,
            flex: 1,
            renderCell: (params) => (
                <Button
                className="action-menu-button"
                aria-controls="action-menu"
                aria-haspopup="true"
                aria-expanded={actionMenuOpen ? 'true' : undefined}
                onClick={event => handleOpenActionMenu(event, params?.row)}
            >
                <ExpandMore />
            </Button>
            ),
        },
      ];

    return (
        <div className="manage-application page-content">
            <div className="go-back" onClick={() => history.goBack()}>
                <ArrowBack/>
                <Typography variant="body2">Back to Dashboard</Typography>
            </div>
            <Typography variant="h4" className="bold">Manage Applications</Typography>
            <div className="home-agent-buttons">
                <Button type="button" color="primary" variant="contained" onClick={() => history.push(`/createApplication?associationId=${association?.id}`)}>Start application</Button>
            </div>
            <Typography variant="h5" className="bold">Applications for  {`${association?.child?.givenName} ${association?.child?.familyName}`}:</Typography>
            <DataTable rows={applicationData} columns={window.screen.width > 1000 ? columns : mobileColumns} className="invite-table" />
            <Menu
                id="action-menu"
                className="action-menu"
                anchorEl={anchorEl}
                open={actionMenuOpen}
                onClose={() => handleCloseActionMenu('')}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                getContentAnchorEl={null}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleOpenStudentProgress(selectedRow)}><Typography variant="body2">Progress</Typography></MenuItem>
                <MenuItem onClick={() => handleDeleteApplication(selectedRow?.id)}><Typography variant="body2">Delete</Typography></MenuItem>
            </Menu>
        </div>
    );
}

export default withStyles(styles)(ManageApplication);
