import { InputLabel, TextField, Typography, Button, Divider } from '@material-ui/core';
import React, { useCallback, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import "./account-settings.scss";
import { patch } from "../../services/api/baseApi"
import { useHistory } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { displayToast } from '../../store/reducers/uiStatusReducer';

const AccountSettings = ({profile: {id, email, additionalType}, fetchUser}) => {
    const { control, handleSubmit, reset } = useForm({"email": email});
    const dispatch = useDispatch();

    useEffect(() => {
        reset({email});
    }, [email, reset]);

    const updateEmailAddress = useCallback(async (email) => {
        try {
            const response = await patch(`${process.env.REACT_APP_INSPIRUS_API_URL}/person/${id}`, 
                {
                    "email": email
                }
            );

            if(response.ok) {
                dispatch(displayToast('Email saved', 'success'));
            } else {
                dispatch(displayToast('Failed to update email', 'error'));
            }
        } catch(error) {
            console.log(error);
            dispatch(displayToast('Failed to update email', 'error'));
        } finally {
            fetchUser();
        }
    }, [id, fetchUser, dispatch]);

    const onSubmit = (data) => {
        updateEmailAddress(data.email);
    };

    const history = useHistory();

    return (
        <div className={`account-settings-wrapper ${additionalType?.includes("AGENT") ? 'account-settings-standalone' : ''} page-content`}>
            { additionalType?.includes("AGENT") && (
                <div className="go-back" onClick={() => history.push('/')}>
                    <ArrowBack/>
                    <Typography variant="body2">Back to Dashboard</Typography>
                </div> 
            )}
            <div className="account-settings">
                <Typography variant="h4" className="registration-subtitle bold">Account Settings</Typography>
                <Typography variant="body2">You can change your email address here.</Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputLabel>Email Address</InputLabel>
                    <Controller
                        render={({ field: { onChange, value }, fieldState: { error } }) =>
                            <TextField variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} fullWidth />
                        }
                        name="email"
                        control={control}
                        className="materialUIInput"
                        rules={{ 
                            required: "Email is required",
                            pattern: {
                                value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/, /* eslint-disable-line */
                                message: "Email address is invalid."
                            }
                        }}
                    />

                    <Divider />

                    <div className="button-wrapper">
                        <Button type="submit" color="primary" variant="contained" fullWidth>Save changes</Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AccountSettings;
