import React, { useCallback, useState, useEffect } from 'react';
import { Typography, Button, DialogContent, DialogTitle, DialogActions, TextField, Dialog, InputLabel, IconButton, Menu, MenuItem, DialogContentText } from '@material-ui/core';
import DefaultImage from '../../../assets/default-profile.svg';
import './landing-page.scss';
import DataTable from '../../common/DataTable';
import { withStyles } from '@material-ui/styles';
import { get, remove, post } from "../../../services/api/baseApi";
import { Close, ExpandMore } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { saveAs } from "file-saver";
import { displayToast } from '../../../store/reducers/uiStatusReducer';

const styles = {
    error: {
        backgroundColor: '#F55D4F',
        color: "#fff"
    }
};

const LandingPage = ({profile, fetchUser, classes, setAssociation}) => {
    const location = useLocation();
    const [associationData, setAssociationData] = useState([]);
    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
    const [selectedAssociation, setSelectedAssociation] = useState();
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
    const [inviteEmail, setInviteEmail] = useState('');
    const [shouldFetchAssociations, setShouldFetchAssociations] = useState(true);
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const actionMenuOpen = Boolean(anchorEl);
    const [selectedRow, setSelectedRow] = useState();
    const dispatch = useDispatch();

    const handleDownloadFiles = (files) => {
        const flattenedFiles = Object.entries(files)
            .flatMap(([key, value]) => value)
            .filter(item => item);

            flattenedFiles.forEach(file => handleDownloadFile(file));
    }

    const handleDownloadFile = (file) => {
        var byteString = atob(file.data.split(',')[1]);
        var mimeString = file.data.split(',')[0].split(':')[1].split(';')[0]
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
    
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
    
        var blob = new Blob([ab], {type: mimeString});
        saveAs(blob, file.file.path);
      };

    const fetchUploads = async (profileId) => {
        try {
            const response = await get(`${process.env.REACT_APP_INSPIRUS_API_URL}/person/${profileId}/uploads`);
            const body = await response.json();
            if (response.ok) {
                handleDownloadFiles(body); 
            } 
        } catch(error) {
            console.log(error);
        } 
    };

    const handleOpenActionMenu = (event, row) => {
        // setAssociation(association);
        setSelectedRow(row);
        setAnchorEl(event.currentTarget);
    };
    const handleCloseActionMenu = () => {
        // setAssociation();
        setSelectedRow();
        setAnchorEl(null);
    };

    const getAssociations = useCallback(() => {
        const fetchUploads = async () => {
            try {
                const response = await get(`${process.env.REACT_APP_INSPIRUS_API_URL}/association`);
                const body = await response.json();
                if (response.ok) {
                    return setAssociationData(body); 
                } 
            } catch(error) {
                console.log(error);
            } 
        };
        fetchUploads();
    }, []);

    useEffect(() => {
        if (shouldFetchAssociations) {
            getAssociations();
            setShouldFetchAssociations(false);
        }
    }, [getAssociations, location.key, shouldFetchAssociations]);

    const handleDeleteAssociation = async () => {
        try {
            const response = await remove(`${process.env.REACT_APP_INSPIRUS_API_URL}/association/${selectedAssociation}`);
            if (response.ok) {
                getAssociations();
            } 
        } catch(error) {
            console.log(error);
        }
        setIsConfirmDeleteModalOpen(false);
        handleCloseActionMenu();
    }
    
    const handleInviteStudent = async () => {
        try {
            const response = await post(`${process.env.REACT_APP_INSPIRUS_API_URL}/association`, {
                "studentEmail": inviteEmail,
                "type": "AGENT_STUDENT"
            });
            if (response.ok) {
                getAssociations();
                setIsInviteModalOpen(false);
                setInviteEmail('');
                dispatch(displayToast('Student invited', 'success'));
            } else {
                const error = JSON.parse(await response.text());
                console.log(error);
                dispatch(displayToast(error.message, 'error'));
            }
        } catch(error) {
            console.log(error);
            dispatch(displayToast('Failed to invite student', 'error'));
        }
    }

    const handleClickDelete = (associationId) => {
        setSelectedAssociation(associationId);
        setIsConfirmDeleteModalOpen(true);
    }

    const handleOpenStudentSnapshot = (selectedAssociation) => {
        setAssociation(selectedAssociation);
        history.push(`/snapshot`)
    }

    const handleOpenManageApplication = (associaton) => {
        setAssociation(associaton);
        history.push('/manage');
    }

    const handleDwonloadAllFiles = (selectedAssociation) => {
        fetchUploads(selectedAssociation.child.id);
    }

    const columns = [
        {
          field: 'avatar',
          headerName: ' ',
          width: 80,
          renderCell: (params) => (
              <div className="user-avatar-wrapper">
                <div className="user-avatar">
                    {(
                        <img src={params?.row?.child?.avatar || DefaultImage} accept="image/*" alt="Profile" />
                    )}
                </div>
              </div>
          ),
          sortable: false,
          disableColumnMenu: true
        },
        {
          field: 'fullName',
          valueFormatter: (params) => `${params?.row?.child?.givenName} ${params?.row?.child?.familyName}`, 
          headerName: 'Name',
          sortable: true,
          flex: 1,
        },
        {
            field: 'inspirusRef',
            headerName: 'Inspirus ID',
            flex: 1,
            valueGetter: (params) => params?.row?.child?.inspirusRef,
          },
        {
          field: 'actions',
          headerName: ' ',
          sortable: false,
          flex: 2,
          renderCell: (params) => (
              <div className="actions-button-container">
                  <Button type="button" color="primary" variant="contained" disabled={params?.row?.child?.status !== 'ACTIVE' || params?.row?.status !== 'ACTIVE'} onClick={() => handleOpenManageApplication(params?.row)}>Manage</Button>
                  <Button type="button" color="primary" variant="contained" disabled={params?.row?.child?.status !== 'ACTIVE' || params?.row?.status !== 'ACTIVE'} onClick={() => handleOpenStudentSnapshot(params?.row)}>View</Button>
                  <Button type="button" color="primary" variant="contained" style={{backgroundColor: '#337347'}}  disabled={params?.row?.child?.status !== 'ACTIVE' || params?.row?.status !== 'ACTIVE'} onClick={() => handleDwonloadAllFiles(params?.row)}>Download</Button>
                  <Button type="button" className={classes.error} onClick={() => handleClickDelete(params?.row?.id)}>Delete</Button>
                  
              </div>
          ),
        },
      ];

      const mobileColumns = [
        {
          field: 'avatar',
          headerName: ' ',
          width: 80,
          renderCell: (params) => (
              <div className="user-avatar-wrapper">
                <div className="user-avatar">
                    {(
                        <img src={params?.row?.child?.avatar || DefaultImage} accept="image/*" alt="Profile" />
                    )}
                </div>
              </div>
          ),
          sortable: false,
          disableColumnMenu: true
        },
        {
          field: 'info',
          headerName: ' ',
          sortable: false,
          flex: 2,
          renderCell: (params) => (
              <div>
                <div className="sm-table-label">
                    <Typography variant="caption">{params?.row?.child?.inspirusRef}</Typography>
                </div>
                <Typography variant="body1">{`${params?.row?.child?.givenName} ${params?.row?.child?.familyName}`}</Typography>
              </div>
          ),
        },
        {
            field: 'actions',
            headerName: ' ',
            sortable: false,
            flex: 1,
            renderCell: (params) => (
                <Button
                className="action-menu-button"
                aria-controls="action-menu"
                aria-haspopup="true"
                aria-expanded={actionMenuOpen ? 'true' : undefined}
                onClick={event => handleOpenActionMenu(event, params?.row)}
            >
                <ExpandMore />
            </Button>
            ),
        },
      ];  

    const handleInviteEmailChange = (event) => {
        setInviteEmail(event.target.value);
    };

    return (
        <div className="home-agent page-content">
            <Typography variant="h4" className="bold">Agent Dashboard</Typography>
            <div className="home-agent-buttons">
                <Button type="button" color="primary" variant="contained" onClick={() => history.push("/createStudent")}>Create a new student account</Button>
                <Button type="button" color="primary" variant="contained" onClick={() => setIsInviteModalOpen(true)}>Connect with Existing Student</Button>
            </div>
            <Typography variant="h5" className="bold">Invited Students:</Typography>
            <DataTable rows={associationData} columns={window.screen.width > 1000 ? columns : mobileColumns} className="invite-table" />
            <Dialog
                open={isConfirmDeleteModalOpen}
                className="dialog-invite"
                fullWidth
                maxWidth="xs"
                onClose={() => setIsConfirmDeleteModalOpen(false)}
            >
                <DialogTitle id="alert-dialog-title">
                {"Confirm delete association?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deleting an association with a student will also remove all applications under that student.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsConfirmDeleteModalOpen(false)}>Disagree</Button>
                    <Button onClick={() => handleDeleteAssociation()} autoFocus>Agree</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isInviteModalOpen}
                className="dialog-invite"
                fullWidth
                maxWidth="xs"
                onClose={() => setIsInviteModalOpen(false)}
            >
                <DialogTitle className="dialog-title">
                    Send invite email
                    <IconButton size="small" aria-label="close" color="secondary" onClick={() => setIsInviteModalOpen(false)}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <InputLabel>Please enter students email address:</InputLabel>
                    <TextField variant="outlined" fullWidth onChange={(event) => handleInviteEmailChange(event)} value={inviteEmail} />
                </DialogContent>
                <DialogActions>
                    <Button type="submit" color="primary" variant="contained" fullWidth onClick={() => handleInviteStudent()}>Send Invitation Email</Button>
                </DialogActions>
            </Dialog>
            <Menu
                id="action-menu"
                className="action-menu"
                anchorEl={anchorEl}
                open={actionMenuOpen}
                onClose={() => handleCloseActionMenu('')}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                getContentAnchorEl={null}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem disabled={selectedRow && (selectedRow.child?.status !== 'ACTIVE' || selectedRow?.status !== 'ACTIVE')} onClick={() => handleOpenManageApplication(selectedRow)}><Typography variant="body2">Manage</Typography></MenuItem>
                <MenuItem disabled={selectedRow && (selectedRow.child?.status !== 'ACTIVE' || selectedRow?.status !== 'ACTIVE')} onClick={() => handleOpenStudentSnapshot(selectedRow)}><Typography variant="body2">View</Typography></MenuItem>
                <MenuItem disabled={selectedRow && (selectedRow.child?.status !== 'ACTIVE' || selectedRow?.status !== 'ACTIVE')} onClick={() => handleDwonloadAllFiles(selectedRow)}><Typography variant="body2">Download</Typography></MenuItem>
                <MenuItem onClick={() => handleClickDelete(selectedRow?.id)}><Typography variant="body2">Delete</Typography></MenuItem>
            </Menu>
        </div>
    );
}

export default withStyles(styles)(LandingPage);
