import createReducer from "../createReducer";

// TYPES
export const DISPLAY_TOAST = 'DISPLAY_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';

// ACTIONS
export const displayToast = (message, severity) => {
    return {
        type: DISPLAY_TOAST,
        message,
        severity,
    }
}

export const hideToast = (message, severity) => {
    return {
        type: HIDE_TOAST,
        message,
        severity,
    }
}

// REDUCER
const initialState = {
    toastOpen: false,
    toastMessage: '',
    toastSeverity: ''
};

export const handleOpenToast = (state, action) => {
    const { message, severity } = action;
    return {
        ...state,
        toastOpen: true,
        toastMessage: message,
        toastSeverity: severity
    }
};

export const handleCloseToast = (state) => {
    return {
        ...state,
        toastOpen: false,
        toastMessage: '',
        toastSeverity: ''
    }
};

export default createReducer(initialState, {
    [DISPLAY_TOAST]: handleOpenToast,
    [HIDE_TOAST]: handleCloseToast,
});