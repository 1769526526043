import React from 'react';
import logoRed from '../../assets/logo-hat.svg';
import twitterLogo from '../../assets/social-tw.svg';
import facebookLogo from '../../assets/social-fb.svg';
import './footer.scss';
import { Typography } from '@material-ui/core';

const Footer = ({keycloakData, hasUserProfile}) => (
    <div className="footer">
        <div className="footer-content page-content">
            <div className="footer-list">
                <ul>
                    <li>
                        <img src={logoRed} className="logo-red" alt="Inspirus Logo" />
                    </li>
                    <li>
                        <Typography variant="caption" className="copyright">
                            {`© Copyright ${new Date().getFullYear()} Inspirus Global Education`}
                        </Typography>
                    </li>
                </ul>
                <ul>
                    <li><h5><a href="https://inspirus.io/universities" target="_blank" rel="noreferrer">Universities</a></h5></li>
                    <li>
                        <Typography variant="caption" className="footer-link">
                            <a href="https://inspirus.io/" target="_blank" rel="noreferrer">Universities Search</a>
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="caption" className="footer-link">
                        <a href="https://inspirus.io/how-it-works" target="_blank" rel="noreferrer">How It Works</a>
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="caption" className="footer-link">
                        <a href="https://inspirus.io/our-story" target="_blank" rel="noreferrer">Our Story</a>
                        </Typography>
                    </li>
                </ul>
                <ul>
                    <li><h5><a href="https://inspirus.io/" target="_blank" rel="noreferrer">Agents</a></h5></li>
                    <li>
                        <Typography variant="caption" className="footer-link">
                        <a href="https://inspirus.io/become-an-agent" target="_blank" rel="noreferrer">Become an Agent</a>
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="caption" className="footer-link">
                        <a href="/" target="_blank" rel="noreferrer">Login</a>
                        </Typography>
                    </li>
                </ul>
                <ul>
                    <li><h5><a href="https://inspirus.io/" target="_blank" rel="noreferrer">Why Study Abroad</a></h5></li>
                    <li>
                        <Typography variant="caption" className="footer-link">
                        <a href="https://inspirus.io/how-it-works" target="_blank" rel="noreferrer">Why Use Inspirus?</a>
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="caption" className="footer-link">
                        <a href="https://inspirus.io/student-contact" target="_blank" rel="noreferrer">Get in Contact</a>
                        </Typography>
                    </li>
                </ul>
                <ul>
                    <li><h5><a href="https://inspirus.io/terms-and-conditions" target="_blank" rel="noreferrer">Terms and Conditions</a></h5></li>
                    <li>
                        <Typography variant="caption" className="footer-link">
                        <a href="https://inspirus.io/terms-and-conditions" target="_blank" rel="noreferrer">Terms and Conditions</a>
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="caption" className="footer-link">
                        <a href="https://inspirus.io/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="caption" className="footer-link">
                        <a href="https://inspirus.io/cookie-policy" target="_blank" rel="noreferrer">Cookie Policy</a>
                        </Typography>
                    </li>
                </ul>
                <ul>
                    <li>
                        <div className="footer-copyright">
                            <div className="footer-social">
                                <a href="https://www.facebook.com/InspirusGlobalEducation/" target="_blank" rel="noreferrer"><img src={facebookLogo} alt="Social Facebook" /></a>
                                <a href="https://twitter.com/inspirused" target="_blank" rel="noreferrer"><img src={twitterLogo} alt="Social Twitter" className="twitter-logo" /></a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <Typography variant="caption" className="copyright copyright-bottom">
                            {`© Copyright ${new Date().getFullYear()} Inspirus Global Education`}
                        </Typography>
                    </li>
                </ul>
            </div>
            {!hasUserProfile && (
                <div className="logout">
                    <h4 onClick={() => keycloakData.keycloak.logout()}>Logout</h4>
                </div>
            )}
        </div>
    </div>
);

export default Footer;
