import './App.css';
import { useCallback, useEffect, useState } from 'react'

import KeycloakFactory from '../../services/auth/keycloak'
import { Redirect, Route, Switch } from 'react-router-dom';
import Registration from '../Registration';
import StudentLandingPage from '../Student/LandingPage';
import AgentLandingPage from '../Agent/LandingPage';
import Footer from '../Footer';
import Header from '../Header';
import { get } from "../../services/api/baseApi";
import CreateStudent from '../Agent/CreateStudent';
import StudentSnapshot from '../Agent/StudentSnapshot';
import { AssociationProvider } from '../../context/Association';
import StudentProgress from '../Agent/StudentProgress';
import Toast from '../common/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { hideToast } from '../../store/reducers/uiStatusReducer';
import ManageApplication from '../Agent/ManageApplication';
import CreateApplication from '../Agent/CreateApplication';
import AgentProfile from '../Registration/Agent/AgentProfile';
import AccountSettings from '../AccountSettings';

function App() {
  const [hasUserProfile, setHasUserProfile] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const [pageTag, setPageTag] = useState();
  const [keycloakData, setKeycloakData] = useState({
    authenticated: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [association, setAssociation] = useState({});
  const [application, setApplication] = useState({});
  const toastOpen = useSelector((state) => state.uiStatus.toastOpen);
  const toastMessage = useSelector((state) => state.uiStatus.toastMessage);
  const toastSeverity = useSelector((state) => state.uiStatus.toastSeverity);
  const dispatch = useDispatch();

  const fetchUser = useCallback(async () => {
    try {
        const response = await get(`${process.env.REACT_APP_INSPIRUS_API_URL}/person/me`);
        const body = await response.json();
        if (response.ok) {
          setHasUserProfile(true);
          setUserProfile(body);
        } else {
          setHasUserProfile(false);
          setUserProfile();
        }
    } catch(error) {
        console.log(error);
    } 
}, []);

  useEffect(() => {
    const verifyLogin = () => {
      const query = new URLSearchParams(window.location.search);
      const accessToken = query.get("accessToken");
      const idToken = query.get("idToken");
      const refreshToken = query.get("refreshToken");

      if (accessToken && idToken && refreshToken) {
        authenticate(accessToken, idToken, refreshToken);
      } else {
        authenticate();
      }
    };
    
    const authenticate = async (accessToken, idToken, refreshToken) => {
      try {
        // let hasUserProfile = false;
        let keycloakInit;
        // let isInvited = false;

        if (accessToken && idToken && refreshToken) {
          keycloakInit = await KeycloakFactory.initFromTokens(accessToken, idToken, refreshToken);
        } else {
          keycloakInit = await KeycloakFactory.init();
        }
        if (keycloakInit.authenticated) {
          const userInfo = await keycloakInit.loadUserInfo();
          setKeycloakData({
            username: userInfo.email,
            authenticated: true,
            keycloak: keycloakInit,
          });
          await fetchUser(keycloakInit.token);
        } else {
          keycloakInit.login();
        }
      } catch (error) {
        console.log(error);
    } finally {
      // appActionCreators.dispatchLoading(dispatch, false);
      setIsLoading(false);
    }
  }
    verifyLogin();
  }, [hasUserProfile, fetchUser]);

  return (
    <>
      <Switch>
        {!keycloakData.authenticated && (
          <>
          <Route exact path="/">
            {/* <Login login={() => keycloakData?.keycloak.login({ redirectUri: KeycloakConfig.loginRedirectUri })} /> */}
          </Route>
          <Route path="/login">
            {/* <Login login={() => keycloakData?.keycloak.login({ redirectUri: KeycloakConfig.loginRedirectUri })} /> */}
          </Route>
          </>
        )}
        {keycloakData.authenticated && (
          <>
            <Route path="/">
              <div className="main-content">
                <Header userProfile={userProfile} keycloakData={keycloakData} setPageTag={setPageTag}/>
                <Route exact path="/registration">
                  <Toast isOpen={toastOpen} message={toastMessage} severity={toastSeverity} handleClose={() => dispatch(hideToast())} />
                  <Registration keycloakData={keycloakData} fetchUser={fetchUser}/>
                </Route>
                {hasUserProfile && ( userProfile?.additionalType?.includes("AGENT") && (
                  <>
                    <Toast isOpen={toastOpen} message={toastMessage} severity={toastSeverity} handleClose={() => dispatch(hideToast())} />
                    <Route exact path="/createStudent">
                      <CreateStudent />
                    </Route>
                    <Route exact path="/createApplication">
                      <CreateApplication />
                    </Route>
                    <Route exact path="/snapshot">
                      <AssociationProvider association={association}>
                        <StudentSnapshot />
                      </AssociationProvider>
                    </Route>
                    <Route exact path="/manage">
                      <AssociationProvider association={association} application={application}>
                        <ManageApplication setApplication={setApplication}/>
                      </AssociationProvider>
                    </Route>
                    <Route exact path="/progress">
                      <AssociationProvider association={association} application={application}>
                        <StudentProgress />
                      </AssociationProvider>
                    </Route>
                    <Route exact path="/editStudent">
                      <StudentLandingPage profile={association.child} fetchUser={() => association} pageTag={pageTag} setPageTag={setPageTag} agentView={true} /> 
                    </Route>
                    <Route exact path="/">
                      <AgentLandingPage profile={userProfile} fetchUser={fetchUser} setAssociation={setAssociation} /> 
                    </Route>
                    <Route exact path="/agentProfile">
                      <AgentProfile profile={userProfile} fetchUser={fetchUser} setAssociation={setAssociation} /> 
                    </Route>
                    <Route exact path="/accountSettings">
                      <AccountSettings profile={userProfile} fetchUser={fetchUser} /> 
                    </Route>
                  </>
                ))}
                {hasUserProfile && ( userProfile?.additionalType?.includes("STUDENT")  && (
                    <Route exact path="/">
                      <Toast isOpen={toastOpen} message={toastMessage} severity={toastSeverity} handleClose={() => dispatch(hideToast())} />
                      <StudentLandingPage profile={userProfile} fetchUser={fetchUser} pageTag={pageTag} setPageTag={setPageTag}/> 
                    </Route>
                ))}
              </div>
            </Route>
            <Footer keycloakData={keycloakData} hasUserProfile={hasUserProfile}/>
          </>
        )}
      </Switch>
      {!isLoading && (
        <>
          {hasUserProfile === false && keycloakData.authenticated && <Redirect to="/registration" />}
          {hasUserProfile === true && keycloakData.authenticated && <Redirect to='/' />}
        </>
      )}
    </>
  );
};

export default App;