import React from 'react';
import { useHistory } from 'react-router';
import { InputLabel, TextField, Typography, Button } from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import './create-student.scss';
import { ArrowBack } from '@material-ui/icons';
import { post } from "../../../services/api/baseApi";
import { useDispatch } from 'react-redux';
import { displayToast } from '../../../store/reducers/uiStatusReducer';


const CreateStudent = () => {
    const history = useHistory();
    const defaultValues = {
        givenName: '',
        familyName: '',
        email: '',
    };

    const dispatch = useDispatch();

    const createStudent = async (data) => {
        const createResponse = await post(`${process.env.REACT_APP_INSPIRUS_API_URL}/person?createdByAgent=true`,
            {
                givenName: data.givenName,
                familyName: data.familyName,
                email: data.email,
                additionalType: [
                    "STUDENT"
                ]
            },
        );
        if(createResponse.ok) {
            dispatch(displayToast('Student created successfully', 'success'));
            const inviteResponse = await post(`${process.env.REACT_APP_INSPIRUS_API_URL}/association`, {
                "studentEmail": data.email,
                "type": "AGENT_STUDENT"
            });
            if (inviteResponse.ok) {
                history.push('/');
            }
        } else {
            const errorResponse = await createResponse.json();
            dispatch(displayToast(errorResponse.message, 'error'));
        }
    }

    const onSubmit = (data) => {
        createStudent(data);
    }

    const { control, handleSubmit } = useForm({defaultValues});
    return (
        <div className="create-student page-content">
            <div className="go-back" onClick={() => history.goBack()}>
                <ArrowBack/>
                <Typography variant="body2">Back to Dashboard</Typography>
            </div>
            <Typography variant="h4" className="registration-subtitle bold">Create Student</Typography>
            <Typography variant="h6">Personal Details</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputLabel>Firstname*</InputLabel>
                <Controller
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                        <TextField variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} fullWidth />
                    }
                    name="givenName"
                    control={control}
                    defaultValue=""
                    className="materialUIInput"
                    rules={{ 
                        required: "Firstname is required",
                        minLength: {
                            value: 2,
                            message: "Firstname must be atleast 2 characters."
                        }
                    }}
                />
                <InputLabel>Lastname*</InputLabel>
                <Controller
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                        <TextField variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} fullWidth />
                    }
                    name="familyName"
                    control={control}
                    defaultValue=""
                    className="materialUIInput"
                    rules={{ 
                        required: "Lastname is required",
                        minLength: {
                            value: 2,
                            message: "Lastname must be atleast 2 characters."
                        }
                    }}
                />
                <InputLabel>Email Address*</InputLabel>
                <Controller
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                        <TextField variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} fullWidth />
                    }
                    name="email"
                    control={control}
                    className="materialUIInput"
                    rules={{ 
                        required: "Email is required",
                        pattern: {
                            value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/, /* eslint-disable-line */
                            message: "Email address is invalid."
                        }
                    }}
                />
                <div className="button-wrapper">
                    <Button type="submit" color="primary" variant="contained" fullWidth>Send registration link</Button>
                </div>
            </form>
        </div>
    );
};

export default CreateStudent;
