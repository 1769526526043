import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';

const DataTable = ({rows, columns, className, isLoading}) => {
  return (
    <>
      {window.screen.width > 1000 && (
        <div style={{ height: 400, width: 'auto' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            rowHeight={80}
            className={className}
            loading={isLoading}
            disableColumnMenu={true}
          />
        </div>
      )}
      {window.screen.width <= 1000 && (
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            rowHeight={80}
            className={className}
            loading={isLoading}
            headerHeight={0}
            show={false}
            disableColumnMenu={true}
          />
        </div>
      )}
    </>
  );
}

export default DataTable;
