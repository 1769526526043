import { Button, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import AgentProfile from './Agent/AgentProfile';
import './registration.scss';
import StudentRegistration from './Student';


const Registration = ({keycloakData, fetchUser}) => {
    const [selectedType, setSelectedType] = useState();
    const [currentStep, setCurrentStep] = useState(1);

    const handleSelectType = type => {
        setSelectedType(type);
    }

    const handleMoveToNextStep = () => {
        setCurrentStep(parseInt(currentStep) + 1);
    }

    const handleMoveToPreviousStep = () => {
        setCurrentStep(parseInt(currentStep) - 1);
    }

    return (
        <div className="registration page-content">
            {currentStep === 1 && (
                <div className="step1 registration-step">
                    <div className="step1-content">
                        <Typography variant="h5" className="registration-subtitle">Firstly, which are you?</Typography>
                        <>
                            <span className={`option-button${selectedType === 'student' ? ' option-button--selected': ''}`} onClick={() => handleSelectType("student")}>A candidate or student</span>
                            <div className="separator">
                                <hr /><span>Or</span><hr />
                            </div>
                            <span className={`option-button${selectedType === 'agent' ? ' option-button--selected': ''}`} onClick={() => handleSelectType("agent")}>An agent</span>
                            <Button disabled={!selectedType} color="primary" variant="contained" className="continue-button" onClick={() => handleMoveToNextStep()}>Continue</Button>
                        </>
                    </div>
                </div>
            )}
            {currentStep === 2 && selectedType === 'agent' && (
                <AgentProfile handleMoveToPreviousStep={handleMoveToPreviousStep} keycloakData={keycloakData} fetchUser={fetchUser}/>
            )}
            {currentStep === 2 && selectedType === 'student' && (
                <StudentRegistration handleMoveToPreviousStep={handleMoveToPreviousStep} keycloakData={keycloakData} fetchUser={fetchUser}/>
            )}
        </div>
    );
}

export default Registration;
