import KeycloakFactory from "../auth/keycloak";

const get = async (endpoint) => {
  const get = async (accessToken) => {
    const resp = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${accessToken}`,
      },
    });
    return resp;
  };

  let tokens = await KeycloakFactory.getTokens();
  const initialFetch = await get(tokens.accessToken);
  if (initialFetch.status !== 401) {
    return initialFetch;
  }
  tokens = await KeycloakFactory.refreshTokens();
  const retry = await get(tokens.accessToken);
  return retry;
};

const post = async (endpoint, data, contentType) => {
  const post = async (accessToken) => {
    const resp = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    });
    return resp;
  };

  let tokens = await KeycloakFactory.getTokens();
  const initialFetch = await post(tokens.accessToken);
  if (initialFetch.status !== 401) {
    return initialFetch;
  }
  tokens = await KeycloakFactory.refreshTokens();
  const retry = await post(tokens.accessToken);
  return retry;
};

const postFile = async (endpoint, data) => {
  const post = async (accessToken) => {
    const fileData = new FormData();
    fileData.append("file", data);

    const resp = await fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: `bearer ${accessToken}`,
      },
      body: fileData,
    });
    return resp;
  };

  let tokens = await KeycloakFactory.getTokens();
  const initialFetch = await post(tokens.accessToken);
  if (initialFetch.status !== 401) {
    return initialFetch;
  }
  tokens = await KeycloakFactory.refreshTokens();
  const retry = await post(tokens.accessToken);
  return retry;
};

const postFeedback = async (endpoint, data) => {
  const resp = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return resp;
};

const put = async (endpoint, data) => {
  const put = async (accessToken) => {
    const resp = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    });

    return resp;
  };

  let tokens = await KeycloakFactory.getTokens();
  const initialFetch = await put(tokens.accessToken);
  if (initialFetch.status !== 401) {
    return initialFetch;
  }
  tokens = await KeycloakFactory.refreshTokens();
  return await put(tokens.accessToken);
};

const patch = async (endpoint, data) => {
  const patch = async (accessToken) => {
    const resp = await fetch(endpoint, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    });

    return resp;
  };

  let tokens = await KeycloakFactory.getTokens();
  const initialFetch = await patch(tokens.accessToken);
  if (initialFetch.status !== 401) {
    return initialFetch;
  }
  tokens = await KeycloakFactory.refreshTokens();
  return await patch(tokens.accessToken);
};

const remove = async (endpoint) => {
  const remove = async (accessToken) => {
    const resp = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${accessToken}`,
      },
    });

    return resp;
  };

  let tokens = await KeycloakFactory.getTokens();
  const initialFetch = await remove(tokens.accessToken);
  if (initialFetch.status !== 401) {
    return initialFetch;
  }
  tokens = await KeycloakFactory.refreshTokens();
  return await remove(tokens.accessToken);
};

export { get, patch, post, postFile, postFeedback, put, remove };
