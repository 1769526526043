import React, { useState, useEffect } from 'react';
import { Controller } from "react-hook-form";
import {DropzoneAreaBase} from 'material-ui-dropzone'
import { AttachFile } from '@material-ui/icons';
import './drag-drop.scss';
import DragDropFileItem from './DragDropFileItem';
import { saveAs } from "file-saver";

const DragDropFile = ({label, control, fileFieldKey, handleSetValue, initialFiles}) => {
  const [files, setFiles] = useState(initialFiles || []);

  useEffect(() => {
    setFiles(initialFiles || []);
  }, [initialFiles]);
  
  const handleChange = (event) => {
    if (files?.length < 1) {
      setFiles(event);
      handleSetValue(fileFieldKey, event);
    } else {
      const newFiles = files;
      event.forEach(file => newFiles.push(file));
      setFiles([...newFiles]);
      handleSetValue(fileFieldKey, [...newFiles]);
    }
  }

  const handleRemoveFile = (index) => {
    const cloneFiles = files;
    cloneFiles.splice(index, 1)
    setFiles([...cloneFiles]);
    handleSetValue(fileFieldKey, [...cloneFiles]);
  };

  const handleDownloadFile = (index) => {
    var file = files[index];
    // window.location.href = '' + file.data;
    var byteString = atob(file.data.split(',')[1]);

    // separate out the mime component
    var mimeString = file.data.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], {type: mimeString});
    saveAs(blob, file.file.path);
  };

  return (
    <div className="dragDrop-container">                
       <Controller
          render={({ field }) => <DropzoneAreaBase 
            fileObjects={files}
            Icon={AttachFile}
            dropzoneText={"Drop files or click here to upload"}
            onAdd={event => handleChange(event)}
            showPreviewsInDropzone={false}
            {...field}
            filesLimit={10}
            /> }
          name={fileFieldKey}
          control={control}
          defaultValue=""
      />
      {files.length > 0 && (
        <>
          <span className="dragDrop-item-container">
          {files.map((file, index) =>
            <DragDropFileItem filename={file.file.name || file.file.path} type={file.file.type} handleRemove={() => handleRemoveFile(index)} handleDownload={() => handleDownloadFile(index)} />
          )}
          </span>
          <br />
        </>
      )}
    </div>)  
};

export default DragDropFile;
