export function createReducer(initialState, handlers) {
    return function(state = initialState, action) {
        const handler = handlers[action.type]

        if (handler) {
            return handler(state, action);
        }
        return state;
    }
}

export default createReducer;
