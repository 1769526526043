import React, { useState, useEffect }  from 'react';
import { useHistory } from 'react-router';
import { InputLabel, Typography, Button, FormLabel } from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import './create-application.scss';
import { ArrowBack } from '@material-ui/icons';
import { post, get } from "../../../services/api/baseApi";
import { useDispatch } from 'react-redux';
import { displayToast } from '../../../store/reducers/uiStatusReducer';
import { useLocation } from "react-router-dom";
import Select from 'react-select';

const CreateApplication = () => {
    const [universityData, setUniversityData] = useState([]);
    const [courseData, setCourseData] = useState([]);

    const [selectedUniversityId, setSelectedUniversityId] = useState('');

    const search = useLocation().search;
    const associationId = new URLSearchParams(search).get("associationId");

    const history = useHistory();

    const dispatch = useDispatch();

    const defaultValues = {
        universityId: '',
        courseLevel: '',
        courseId: '',
    };

    const courseLevels = ["Undergraduate", "Postgraduate"]

    const { control, handleSubmit, setValue } = useForm({defaultValues});

    useEffect(() => {
        const getUniversities = async () => {
            try {
                const response = await get(`${process.env.REACT_APP_INSPIRUS_API_URL}/university`);
                const body = await response.json();
                if (response.ok) {
                    setUniversityData(body);                    
                } 
            } catch(error) {
                console.log(error);
            } 
        }
        getUniversities();
    }, []);

    useEffect(() => {
        const getCourses = async () => {
            try {
                const response = await get(`${process.env.REACT_APP_INSPIRUS_API_URL}/university/${selectedUniversityId}/course`);
                const body = await response.json();
                if (response.ok) {
                    const courseMetadata = await get(`${process.env.REACT_APP_INSPIRUS_API_URL}/constant/course-levels`);
                    if(courseMetadata.ok) {
                        const courseMetadataBody = await courseMetadata.json();
                        const bachelorsLevel = courseMetadataBody.BACHELORS_DEGREE.awLevel;
                        setCourseData(body[bachelorsLevel].filter(item => item.major === "1"));
                    }                   
                } else {
                    setCourseData([]); 
                }
            } catch(error) {
                console.log(error);
            } 
        }
        if(selectedUniversityId) {
            getCourses();
        } else {
            setCourseData([]);
        }
    }, [selectedUniversityId]);

    const createApplication = async (data) => {
        console.log(data);
        const createResponse = await post(`${process.env.REACT_APP_INSPIRUS_API_URL}/application`,
            {
                associationId: associationId,
                universityId: data.universityId,
                courseId: data.courseId,
                courseName: data.courseName,
                courseLevel: data.courseLevel,
            }
        );
        if(createResponse.ok) {
            history.push('/manage');
        } else {
            dispatch(displayToast('An error ocurred whilst creating student', 'error'));
        }
    }

    const onSubmit = (data) => {
        const dataToSubmit = {
            courseId: data.courseId.value,
            courseName: data.courseId.label,
            universityId: data.universityId,
            courseLevel: data.courseLevel.value,
        };
        createApplication(dataToSubmit);
    }

    const handleUniversityDropdownChange = (event) => {
        setValue('universityId', event?.value);
        setSelectedUniversityId(event?.value);
        setValue('courseId', '');
    };

    return (
        <div className="create-application page-content">
            <div className="go-back" onClick={() => history.goBack()}>
                <ArrowBack/>
                <Typography variant="body2">Back to Applications</Typography>
            </div>
            <Typography variant="h4" className="registration-subtitle bold">Create Application</Typography>
            <Typography variant="h6">Application Details</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputLabel>University*</InputLabel>
                <Controller render={({ field: { onChange, value }, fieldState: { error } }) =>
                    <>
                        <FormLabel error>{error?.message}</FormLabel>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isLoading={!universityData}
                            isClearable
                            isSearchablex
                            name="color"
                            options={universityData.map(i => {return {value: i.uid, label: i.title}})}
                            onChange={(event, triggeredAction) => {
                                if (triggeredAction.action === 'clear') {
                                  setSelectedUniversityId();
                                  setCourseData([]);
                                  setValue('courseId', '');
                                } else {
                                    handleUniversityDropdownChange(event);
                                }
                            }}
                        />
                    </>
                    }
                    name="universityId"
                    control={control}
                    defaultValue=""
                    className="materialUIInput"
                    rules={{ 
                        required: "University is required",
                        minLength: {
                            value: 1,
                            message: "University is required."
                        }
                    }}
                />

                <InputLabel>Course Level</InputLabel>
                <Controller
                    name="courseLevel"
                    render={({ field }) => (
                    <Select
                        {...field}
                        className="basic-single"
                        classNamePrefix="select"
                        options={courseLevels.map(i => {return {value: i, label: i}})}
                        isClearable
                        isSearchable
                    />
                    )}
                    control={control}
                    defaultValue=""
                />

                <InputLabel>Course</InputLabel>
                <Controller
                    name="courseId"
                    render={({ field }) => (
                    <Select
                        {...field}
                        className="basic-single"
                        classNamePrefix="select"
                        options={courseData.map(i => {return {value: i.cid, label: i.course}})}
                        isDisabled={courseData.length === 0}
                        isClearable
                        isSearchable
                    />
                    )}
                    control={control}
                    defaultValue=""
                />
                <div className="button-wrapper">
                    <Button type="submit" color="primary" variant="contained" fullWidth>Start Application</Button>
                </div>
            </form>
        </div>
    );
};

export default CreateApplication;
