import React from 'react';
import { PictureAsPdf, Cancel, Description, Image } from '@material-ui/icons';
import './drag-drop-file-item.scss';
import { Tooltip } from '@material-ui/core';

const DragDropFileItem = ({ filename, type, handleRemove, handleDownload }) => {
  return (
    <div className="dragDrop-item ">
      <div onClick={handleDownload}>
        {type === undefined && (
          <Description />
        )}
        {type === 'application/pdf' && (
          <PictureAsPdf />
        )}
        {type && type.includes('image') && (
          <Image />
        )}
        {type && type !== 'application/pdf' && !type.includes('image') && (
          <Description />
        )}
      </div>
      <Tooltip title={filename} className="dragDrop-item-tooltip">
        <h5>{filename}</h5>
      </Tooltip>
      <Cancel className="cancel-icon" onClick={handleRemove} />
    </div>
  )
};

export default DragDropFileItem;
