import React, { useState, forwardRef } from 'react';
import './header.scss';
import logo from '../../assets/logo-hat.svg';
import UserDropdown from './UserDropdown';
import { Dialog, Divider, MenuItem, Slide, Typography, Menu } from '@material-ui/core';
import { Menu as MenuIcon, Close, ExpandLess, ExpandMore } from '@material-ui/icons';
import DefaultImage from '../../assets/default-profile.svg';
import KeycloakFactory from '../../services/auth/keycloak';
import { useHistory } from 'react-router-dom';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction='down' ref={ref} {...props} />;
});

const Header = ({ userProfile, setPageTag }) => {

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleDialogHeaderNav = (pageTag) => {
        setPageTag(pageTag);
        setDrawerOpen(false);
    };

    const handleNavigate = (uri) => {
        setDrawerOpen(false);
        history.push(uri);
    };

    const history = useHistory();

    return (
        <div className="header-wrapper">
            <div className="header">
                <div className="logo-container">
                    <img src={logo} alt="Inspirus Logo" className="logo" />
                </div>
                <ul className="header-nav">
                    <li><Typography variant="button"><a href="https://inspirus.io/" rel="noreferrer">Home</a></Typography></li>
                    <li><Typography variant="button"><a href="https://inspirus.io/universities" rel="noreferrer">Universities</a></Typography></li>
                    <li onClick={e => setAnchorEl(anchorEl ? null : e.currentTarget)}><Typography variant="button"><span className="about-us">About Us{anchorEl ? <ExpandLess /> : <ExpandMore />}</span></Typography></li>
                    <li><Typography variant="button"><a href="https://inspirus.io/blog" rel="noreferrer">Blog</a></Typography></li>
                    <li><Typography variant="button"><a href="https://inspirus.io/events" rel="noreferrer">Events</a></Typography></li>
                    <li><Typography variant="button"><a href="https://inspirus.io/student-contact" rel="noreferrer">Contact Us</a></Typography></li>
                    <Menu
                        id="about-menu"
                        className="about-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        getContentAnchorEl={null}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        PaperProps={{
                            style: {
                                borderRadius: '10px',
                                boxShadow: 'none',
                                border: '1px solid #000',
                                marginTop: '8px',
                            }
                        }}
                    >
                        <MenuItem><Typography variant="button"><a href="https://inspirus.io/how-it-works" rel="noreferrer">How It Works</a></Typography></MenuItem>
                        <MenuItem><Typography variant="button"><a href="https://inspirus.io/our-story" rel="noreferrer">Our Story</a></Typography></MenuItem>
                    </Menu>
                </ul>
                <div className="hamburger-wrapper">
                    {drawerOpen && (
                        <Close className="hamburger" onClick={() => setDrawerOpen(!drawerOpen)} />
                    )}
                    {!drawerOpen && (
                        <MenuIcon className="hamburger" onClick={() => setDrawerOpen(!drawerOpen)} />
                    )}
                </div>
                <UserDropdown
                    additionalType={userProfile?.additionalType}
                    username={userProfile?.givenName}
                    avatar={userProfile?.avatar}
                    setPageTag={setPageTag}
                    isHidden={!userProfile}
                />
                <Dialog
                    anchor="top"
                    open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                    TransitionComponent={Transition}
                    fullScreen
                    hideBackdrop
                    className="header-dialog"
                    PaperProps={{
                        style: {
                            boxShadow: 'none'
                        },
                        className: 'header-dialog-paper'
                    }}
                    transitionDuration={500}
                >
                    <div className="header-wrapper">
                        <div className="header">
                            <div className="logo-container">
                                <img src={logo} alt="Inspirus Logo" className="logo" />
                            </div>
                            <Close className="hamburger" onClick={() => setDrawerOpen(!drawerOpen)} />
                        </div>
                        <ul className="header-nav">
                            {userProfile &&
                                (<>
                                    <li className="user-profile-link" onClick={() => userProfile?.additionalType?.includes("AGENT") ? handleNavigate("/agentProfile") : handleDialogHeaderNav('profile')}>
                                        <div className="user-avatar">
                                            {(
                                                <img src={userProfile?.avatar || DefaultImage} accept="image/*" alt="Profile" />
                                            )}
                                        </div>
                                        <Typography variant="button">{userProfile?.givenName}</Typography>
                                    </li>
                                </>)
                            }

                            <li><Typography variant="button"><a href="https://inspirus.io/" rel="noreferrer">Home</a></Typography></li>
                            <li><Typography variant="button"><a href="https://inspirus.io/universities" rel="noreferrer">Universities</a></Typography></li>
                            <li><Typography variant="button"><a href="https://inspirus.io/how-it-works" rel="noreferrer">How It Works</a></Typography></li>
                            <li><Typography variant="button"><a href="https://inspirus.io/our-story" rel="noreferrer">Our Story</a></Typography></li>
                            <li><Typography variant="button"><a href="https://inspirus.io/blog" rel="noreferrer">Blog</a></Typography></li>
                            <li><Typography variant="button"><a href="https://inspirus.io/events" rel="noreferrer">Events</a></Typography></li>
                            <li><Typography variant="button"><a href="https://inspirus.io/student-contact" rel="noreferrer">Contact Us</a></Typography></li>
                            {userProfile &&
                                (<>
                                    <Divider />
                                    <li onClick={() => userProfile?.additionalType?.includes("AGENT") ? handleNavigate("/accountSettings") : handleDialogHeaderNav('account-settings')}><Typography variant="button">Account Settings</Typography></li>
                                    <li><Typography variant="button" onClick={() => KeycloakFactory.logout()}>Logout</Typography></li>
                                </>)
                            }
                        </ul>
                    </div>
                </Dialog>
            </div>
        </div>
    );
}

export default Header;
