import React, { useCallback, useState, useEffect, useRef } from 'react';
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { Divider, InputLabel, Typography, TextField, Button, FormControlLabel, Checkbox} from '@material-ui/core';
import DragDropFile from '../../common/DragDropFile'
import Toast from '../../common/Toast';
import { post, put, get } from "../../../services/api/baseApi";
import { useHistory } from "react-router-dom";
import { ArrowBack } from '@material-ui/icons';
import './agent-profile.scss';
import { useDispatch } from 'react-redux';
import { displayToast } from '../../../store/reducers/uiStatusReducer';
import { countryNames } from '../../../constants/constants';
import DefaultImage from '../../../assets/default-profile.svg';


const AgentProfile = ({fetchUser, profile}) => {
    const [profileImage, setProfileImage] = useState(DefaultImage);
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [showAgencyName, setShowAgencyName] = useState();
    const [universityData, setUniversityData] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const uploadInput = useRef(null);

    const defaultValues = {
        avatar: '',
        givenName: '',
        familyName: '',
        type: 'agent',
        bio: '',
        regionsCovered: '',
        associatedUniversities: '',
        partOfAnAgency: '',
        evidence: [],
        evidenceText: ''
    };

    const handleOpenToast = (open, message) => {
        setToastMessage(message);
        setToastOpen(open);
    };

    const createAgent = useCallback(async (data) => {
        try {
            const profileData = {
                avatar: data.avatar,
                givenName: data.givenName,
                familyName: data.familyName,
                evidence: data.evidence,
                evidenceText: data.evidenceText,
                bio: data.bio,
                regionsCovered: data.regionsCovered,
                associatedUniversities: data.associatedUniversities,
                partOfAnAgency: data.partOfAnAgency,
                agencyName: data.agencyName,
                additionalType: [
                    "AGENT"
                ]
            };

            const response = profile ? await put(`${process.env.REACT_APP_INSPIRUS_API_URL}/person/${profile.id}`, profileData)
                            : await post(`${process.env.REACT_APP_INSPIRUS_API_URL}/person`, profileData);

            if(response.ok) {
                dispatch(displayToast('Profile saved', 'success'));
                fetchUser();
                // history.push('/');
            } else {
                // setErrors(JSON.parse(await response.text()));
                dispatch(displayToast('Failed to update profile', 'error'));
            }

        } catch(error) {
            handleOpenToast(true, "Error creating Student");
        } 
    }, [fetchUser, dispatch, profile]);


    const { control, handleSubmit, setValue, reset } = useForm({defaultValues});

  const onSubmit = (data) => {
    createAgent(data);
  };

  const getUniversityData = useCallback(() => {
    const fetchUniversityData = async () => {
        try {
            const response = await get(`${process.env.REACT_APP_INSPIRUS_API_URL}/university`);
            const body = await response.json();
            if (response.ok) {
                setUniversityData(body); 
            } 
        } catch(error) {
            console.log(error);
        } 
    };
    fetchUniversityData();
}, []);

  useEffect(() => {
    setProfileImage(profile?.avatar || DefaultImage)
    setShowAgencyName(profile?.partOfAnAgency);
    reset(profile);
}, [profile, reset]);

  useEffect(() => {
      getUniversityData();
  }, [getUniversityData]);

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
  
      reader.onload = () => {
        resolve(reader.result);
      };
  
      reader.onerror = reject;
  
      reader.readAsDataURL(file);
    })
  }

  const handleProfileImageUpload = async (event) => {
    const profileImage = event.target.files[0];

    try {
        let resp = await readFileAsync(profileImage)
    
        setProfileImage(resp);
        setValue('avatar', resp);

    } catch(error) {
        console.log(error)
    }
  }

  const handleRemoveImage = () => {
    setProfileImage(DefaultImage);
    setValue("avatar", "")
  }  

  return (
    <div className="agent-profile page-content">
        { profile && (
                <div className="go-back" onClick={() => history.goBack()}>
                    <ArrowBack/>
                    <Typography variant="body2">Back to Dashboard</Typography>
                </div> 
            )}
        <div className="agent-profile-content">
            <Typography variant="h5" className="registration-subtitle">{profile ? 'Profile' : 'Hello Inspirus agent! Please fill in your details below.'}</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="profile-upload">
                    <div className="profile-upload-preview">
                        {profileImage && (
                            <img src={profileImage} accept="image/*" alt="Profile" />
                        )}
                    </div>
                    <div className="profile-upload-actions">
                        <input type="file" onChange={handleProfileImageUpload} ref={uploadInput} hidden />
                        <Button fullWidth variant="outlined" onClick={() => uploadInput.current.click()}>Upload New Image</Button>
                        <Button fullWidth variant="outlined" onClick={() => handleRemoveImage()}>Delete Image</Button>
                    </div>
                </div>
                <InputLabel>Firstname*</InputLabel>
                <Controller
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                        <TextField variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} fullWidth />
                    }
                    name="givenName"
                    control={control}
                    defaultValue=""
                    className="materialUIInput"
                    rules={{ 
                        required: "Firstname is required",
                        minLength: {
                            value: 3,
                            message: "Firstname must be atleast 3 characters."
                        }
                    }}
                />
                <InputLabel>Lastname*</InputLabel>
                <Controller
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                        <TextField variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null}  fullWidth/>
                    }
                    name="familyName"
                    control={control}
                    defaultValue=""
                    rules={{ 
                        required: "Lastname is required",
                        minLength: {
                            value: 3,
                            message: "Lastname must be atleast 3 characters."
                        }
                    }}
                />
                {/* <InputLabel>Email Address*</InputLabel>
                <Controller
                    render={({ field }) => <TextField disabled variant="outlined" {...field} />}
                    name="emailAddress"
                    control={control}
                    defaultValue={profile.email || keycloakData.username}
                /> */}
                <InputLabel>Bio</InputLabel>
                <Controller
                    render={({ field }) => <TextField 
                        variant="outlined"
                        multiline
                        rows={8}
                        {...field}
                        fullWidth 
                    />}
                    name="bio"
                    control={control}
                    defaultValue=""
                />
                <InputLabel>Region Covered</InputLabel>
                <Controller
                    name="regionsCovered"
                    render={({ field }) => (
                    <Select
                        {...field}
                        isMulti
                        options={countryNames.map(i => {return {value: i, label: i}})}
                    />
                    )}
                    control={control}
                    defaultValue=""
                />
                <InputLabel>Associated Universities</InputLabel>
                <Controller
                    name="associatedUniversities"
                    render={({ field }) => (
                    <Select
                        {...field}
                        isMulti
                        options={universityData.map(i => {return {value: i.uid, label: i.title}})}

                    />
                    )}
                    control={control}
                    defaultValue=""
                />
                <FormControlLabel
                    control={
                        <Controller
                            name="partOfAnAgency"
                            render={(props) => (
                                <Checkbox
                                    checked={!!props.field.value}
                                    onChange={(e) => {
                                        setShowAgencyName(e.target.checked);
                                        props.field.onChange(e.target.checked);
                                    }
                                    }
                                    color="primary"
                                />
                            )}
                            control={control}
                            defaultValue=""
                        />
                    }
                    label={<InputLabel>Are you part of an agency?</InputLabel>}
                />
                
                {showAgencyName && (
                    <>
                        <InputLabel>Agency Name</InputLabel>
                        <Controller
                            render={({ field: { onChange, value }, fieldState: { error } }) =>
                                <TextField variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null}  fullWidth/>
                            }
                            name="agencyName"
                            control={control}
                            defaultValue=""
                        />
                    </> 
                )}

                <InputLabel>Give evidence of students placed and of your University Contracts</InputLabel>
                <Controller
                    render={({ field }) => <TextField 
                        variant="outlined"
                        multiline
                        rows={8}
                        {...field}
                        fullWidth
                    />}
                    name="evidenceText"
                    control={control}
                    defaultValue=""
                />
                <br />
                <br />
                <DragDropFile 
                    control={control} 
                    fileFieldKey="evidence"
                    handleSetValue={setValue}
                    initialFiles={profile?.evidence}
                />

                <Divider />

                <div className="button-wrapper">
                    {/* <Button color="secondary" variant="contained" onClick={() => handleMoveToPreviousStep(2)}>Cancel</Button> */}
                    <Button type="submit" variant="contained" color="primary" fullWidth>Save and continue</Button>
                </div>
                <Toast message={toastMessage} isOpen={toastOpen} handleClose={() => setToastOpen(false)} />
            </form>
        </div>
    </div>
  )};

export default AgentProfile;
