const setCookie = (cookieKey, cookieValue, expirationDays) => {
  let expiryDate = '';

  if (expirationDays) {
    const date = new Date();

    date.setTime(`${date.getTime()}${(expirationDays || 30 * 24 * 60 * 60 * 1000)}`);

    expiryDate = `; expiryDate=" ${date.toUTCString()}`;
  }

  document.cookie = `${cookieKey}=${cookieValue || ''}${expiryDate}; path=/; SameSite=None; Secure;`;
}

const getCookie = (cookieKey) => {
  let cookieName = `${cookieKey}=`;

  let cookieArray = document.cookie.split(';');

  for (let cookie of cookieArray) {

    while (cookie.charAt(0) === ' ') {
          cookie = cookie.substring(1, cookie.length);
      }

    if (cookie.indexOf(cookieName) === 0) {
          return cookie.substring(cookieName.length, cookie.length);
      }
  }
}

document.addEventListener("DOMContentLoaded", function() {

	const cookieSet = getCookie("inspiruscookiebar")

	if(cookieSet === 1) {
		return
	}

	const node = document.createElement("div")
	const content = document.createElement("div")
	const button = document.createElement("button")
	button.innerHTML = "OK"
	const textnode = document.createTextNode("This website uses cookies")

	node.style.cssText = `
	  	position: fixed;
	  	display: flex;
	  	justify-content: center;
	  	align-items: center;
		bottom: 0px;
		left: 0px;
		height: 40px;
		width: 100vw;
		background: #000;
		color: #fff;
		font-size: 12px;
		z-index: 1000;
	`;

	button.style.cssText = `
		margin-left: 15px;
	`;

	content.appendChild(textnode)
	content.appendChild(button)
	node.appendChild(content)
	document.body.append(node)
	

	button.onclick = function() {
		// -1 = date in the past, expires on session end
		setCookie('inspiruscookiebar', 1, -1)
		document.body.removeChild(node)
	}

})