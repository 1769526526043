import { FormControlLabel, InputLabel, Typography, Checkbox, IconButton, Tooltip, Button, DialogTitle, Dialog, DialogContent, DialogContentText, DialogActions, withStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import './shortlisting.scss';
import { get, put } from "../../services/api/baseApi";
import Carousel from 'react-material-ui-carousel';
import { Delete } from "@material-ui/icons";

const styles = {
    error: {
        backgroundColor: '#F55D4F',
        color: "#fff"
    },
    errorIcon: {
        color: '#F55D4F',
    },
};

const Shortlisting = ({ profile, classes, agentView }) => {
    const [shortlist, setShortlist] = useState([]);
    const [compare, setCompare] = useState([]);
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [refreshShortlist, setRefreshShortlist] = useState(false);

    // useEffect(() => {
    //     var newCompare = compare?.at(-1);
    //     if (newCompare) {
    //         let item = document.getElementById(newCompare);
    //         item.scrollIntoView({ block: 'start', behavior: 'smooth' });
    //     }
    // }, [compare]);

    useEffect(() => {
        const getShortlist = async () => {
            try {
                const response = await get(`${process.env.REACT_APP_INSPIRUS_API_URL}/person/${profile.id}/shortlist/expand`);
                const body = await response.json();
                if (response.ok) {
                    setShortlist(body)
                    setCompare(body.map(i=> i.uid))
                }
            } catch (error) {
                console.log(error);
            }
            setRefreshShortlist(false);
        }
        getShortlist();
    }, [profile, refreshShortlist]);

    const addCompare = (uid) => {
        setCompare([
            ...compare,
            uid
        ]);
    }

    const isCompared = (id) => {
        return compare.length > 0 && compare.includes(id);
    }

    const removeCompare = (uid) => {
        setCompare(compare.filter(i => i !== uid))
    }

    const handleRemoveFromShortlist = item => {
        setIsConfirmDeleteModalOpen(true);
        setSelectedItem(item);
    };

    const removeFromShortlist = async itemToRemove => {
        try {
            const { uid } = itemToRemove;
            const newShortlist = shortlist.map(i => i.uid).filter(i => i !== uid);
            const response = await put(`${process.env.REACT_APP_INSPIRUS_API_URL}/person/${profile.id}/shortlist`, newShortlist);

            if (response.ok) {
                setSelectedItem();
                setIsConfirmDeleteModalOpen(false);
                setRefreshShortlist(true);
                setCompare(compare.map(i => i).filter(i => i !== uid));
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (<div className="shortlisting">
        <Typography variant="h4" className="registration-subtitle bold">Shortlisting</Typography>
        <Typography variant="body1" className="shortlisting-subtitle">Shortlist your favorite universities and compare them together!</Typography>
        <Typography variant="h5">Here's your shortlist:</Typography>
        <div className="shortlist">
            {shortlist.map(item => {
                return (
                    <div className="university">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={compare?.length > 0 && compare?.find(compareTo => compareTo === item.uid)}
                                    onChange={(e) => {
                                        if (isCompared(item.uid)) {
                                            removeCompare(item.uid);
                                        } else {
                                            addCompare(item.uid);
                                        }
                                    }}
                                    color="primary"
                                />
                            }
                            label={<InputLabel>{item?.institutionName}</InputLabel>}
                        />
                        { !agentView && (
                            <div>
                                <Tooltip title={`Remove ${item?.institutionName} from shortlist`}>
                                    <IconButton onClick={() => handleRemoveFromShortlist(item)}>
                                        <Delete className={classes.errorIcon} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
        <div className="compare-container">
            {compare?.length > 0 && (
                <>
                    <Typography variant="h5">Compare your shortlist</Typography>
                    <Carousel
                        navButtonsProps={{
                            style: {
                                opacity: 1,
                                bottom: '0',
                                top: 'unset',
                                backgroundColor: '#4FADF2',
                                borderRadius: '10px'
                            }
                        }}
                        navButtonsWrapperProps={{
                            style: {
                            }
                        }}
                        autoPlay={false}
                    >
                        {shortlist.filter(item => compare.includes(item.uid)).map(item => (
                            <div className="compare-wrapper" id={item.uid}>
                                <Typography variant="h5">{item.institutionName}</Typography >
                                <div className="compare">
                                    <table>
                                        {item?.universityPhoto[0].url && (
                                            <tr>
                                                <td colSpan="2" className="compare-university-photo">
                                                    <img src={item?.universityPhoto[0].url} accept="image/*" alt={item.institutionName} />
                                                </td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td>
                                                <Typography variant="body2">Reading and Writing: 25th - 75th</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body2">{item.SATEBRW25Score} - {item.SATEBRW75Score}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body2">Maths: 25th - 75th</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body2">{item.SATMath25Score} - {item.SATMath75Score}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body2">Total enrollment numbers</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body2">{item.totalEnrollment}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body2">Institution size category</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body2">{item.institutionSizeCategory}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body2">Undergraduate applicaton fee</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body2">{item.undergraduateApplicationFee}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body2">Average tuition fees</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body2">{item.averageTutionPerYearUndergraduates}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body2">Required fees</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body2">{item.OOSRequiredFeesForFullTimeUndergraduates}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body2">Total fees per year</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body2">{parseInt(item.OOSRequiredFeesForFullTimeUndergraduates) + parseInt(item.averageTutionPerYearUndergraduates) + parseInt(item.undergraduateApplicationFee)}</Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Typography variant="body2">Acceptance rate</Typography>
                                            </td>
                                            <td>
                                                <Typography variant="body2">{`${item.percentAdmittedTotal}% of ${item.totalEnrollment} applicants were admitted.`}</Typography>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </>
            )}
            <Dialog
                open={isConfirmDeleteModalOpen}
                className="dialog-invite"
                fullWidth
                maxWidth="xs"
                onClose={() => setIsConfirmDeleteModalOpen(false)}
            >
                <DialogTitle id="alert-dialog-title">
                    Remove from shortlist
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to remove <span>{selectedItem?.institutionName}</span> from your shortlist?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setIsConfirmDeleteModalOpen(false);
                            setSelectedItem();
                        }}
                        color="primary"
                        variant="contained"
                    >
                        Cancel
                    </Button>
                    <Button onClick={() => removeFromShortlist(selectedItem)} className={classes.error}>Remove</Button>
                </DialogActions>
            </Dialog>
        </div>
    </div>
    )
};

export default withStyles(styles)(Shortlisting);
