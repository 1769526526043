import { InputLabel, TextField, Typography, Button, Divider, Checkbox, FormControlLabel } from '@material-ui/core';
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import React, { useRef, useState, useEffect, useCallback } from 'react';
import DefaultImage from '../../../assets/default-profile.svg';
import './profile.scss';
import { put, post } from "../../../services/api/baseApi";
import { useDispatch } from 'react-redux';
import { displayToast } from '../../../store/reducers/uiStatusReducer';
import { nationalities } from '../../../constants/constants';
import { countryNames } from '../../../constants/constants';

const Profile = ({ profile, fetchUser }) => {
    const [profileImage, setProfileImage] = useState(DefaultImage);
    const [lockSubmit, setLockSubmit] = useState(false);
    const [errors, setErrors] = useState({});
    const uploadInput = useRef(null);
    const dispatch = useDispatch();

    const defaultValues = {
        givenName: '',
        familyName: '',
        regionsCovered: '',
        associatedUniversities: '',
        partOfAnAgency: '',
        placementEvidence: [],
        placementEvidenceText: '',
        avatar: '',
        facebook: '',
        twitter: '',
        instagram: '',
        linkedIn: '',
        studentBio: '',
        countryOfResidence: '',
        acceptTsAndCs: false,
    };


    const { control, handleSubmit, reset, setValue } = useForm({ defaultValues });

    const updateStudentProfile = useCallback(async (data) => {
        setLockSubmit(true);
        const profileData = {
            "avatar": data.avatar,
            "givenName": data.givenName,
            "familyName": data.familyName,
            "birthDate": data.birthDate || '',
            "phoneNumber": data.phoneNumber,
            "addressLineOne": data.addressLineOne,
            "addressLineTwo": data.addressLineTwo,
            "city": data.city,
            "country": data.country,
            "zip": data.zip,
            "grades": data.grades,
            "intake": data.intake,
            "studyInfo": data.studyInfo,
            "facebook": data.facebook,
            "twitter": data.twitter,
            "instagram": data.instagram,
            "linkedIn": data.linkedIn,
            "studentBio": data.studentBio,
            "countryOfResidence": data.countryOfResidence,
        };
        const response = profile ? await put(`${process.env.REACT_APP_INSPIRUS_API_URL}/person/${profile.id}`, profileData)
            : await post(`${process.env.REACT_APP_INSPIRUS_API_URL}/person`, { ...profileData, additionalType: ["STUDENT"] });


        if (response.ok) {
            fetchUser();
            dispatch(displayToast('Settings saved', 'success'));
        } else {
            setErrors(JSON.parse(await response.text()));
            dispatch(displayToast('Failed to update profile', 'error'));
        }
        setLockSubmit(false);
    }, [profile, fetchUser, dispatch]);


    useEffect(() => {
        setProfileImage(profile?.avatar || DefaultImage)
        reset(profile);
    }, [profile, reset]);

    const onSubmit = (data) => {
        setErrors({});
        updateStudentProfile(data);
    };

    const handleProfileImageUpload = async (event) => {
        const profileImage = event.target.files[0];

        try {
            let resp = await readFileAsync(profileImage)

            setProfileImage(resp);
            setValue('avatar', resp);

        } catch (error) {
            console.log(error)
        }
    }

    const handleRemoveImage = () => {
        setProfileImage(DefaultImage);
        setValue("avatar", "")
    }

    const readFileAsync = (file) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsDataURL(file);
        })
    }


    return (
        <div className="profile">
            <Typography variant="h4" className="registration-subtitle bold">Profile</Typography>
            <Typography variant="body2">Please complete your profile.</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="profile-upload">
                    <div className="profile-upload-preview">
                        {profileImage && (
                            <img src={profileImage} accept="image/*" alt="Profile" />
                        )}
                    </div>
                    <div className="profile-upload-actions">
                        <input type="file" onChange={handleProfileImageUpload} ref={uploadInput} hidden />
                        <Button fullWidth variant="outlined" onClick={() => uploadInput.current.click()}>Upload New Image</Button>
                        <Button fullWidth variant="outlined" onClick={() => handleRemoveImage()}>Delete Image</Button>
                    </div>
                </div>
                <InputLabel>Firstname*</InputLabel>
                <Controller
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                        <TextField variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} fullWidth />
                    }
                    name="givenName"
                    control={control}
                    defaultValue=""
                    className="materialUIInput"
                    rules={{
                        required: "Firstname is required",
                        minLength: {
                            value: 2,
                            message: "Firstname must be atleast 2 characters."
                        }
                    }}
                />
                <InputLabel>Lastname*</InputLabel>
                <Controller
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                        <TextField variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} fullWidth />
                    }
                    name="familyName"
                    control={control}
                    defaultValue=""
                    className="materialUIInput"
                    rules={{
                        required: "Lastname is required",
                        minLength: {
                            value: 2,
                            message: "Lastname must be atleast 2 characters."
                        }
                    }}
                />
                <InputLabel>Date of Birth*</InputLabel>
                <Controller
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                        <TextField inputProps={{ max: new Date().toISOString().split("T")[0] }} id="date" type="date" variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} fullWidth />
                    }
                    name="birthDate"
                    control={control}
                    defaultValue=""
                    className="materialUIInput"
                    rules={{
                        required: "Date of Birth is required",
                    }}
                />
                <InputLabel>Phone Number</InputLabel>
                <Controller
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                        <TextField variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} fullWidth />
                    }
                    name="phoneNumber"
                    control={control}
                    defaultValue=""
                    className="materialUIInput"
                />
                <InputLabel>Address Line 1</InputLabel>
                <Controller
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                        <TextField variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} fullWidth />
                    }
                    name="addressLineOne"
                    control={control}
                    defaultValue=""
                    className="materialUIInput"
                />
                <InputLabel>Address Line 2</InputLabel>
                <Controller
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                        <TextField variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} fullWidth />
                    }
                    name="addressLineTwo"
                    control={control}
                    defaultValue=""
                    className="materialUIInput"
                />
                <InputLabel>Town/City</InputLabel>
                <Controller
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                        <TextField variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} fullWidth />
                    }
                    name="city"
                    control={control}
                    defaultValue=""
                    className="materialUIInput"
                />
                <InputLabel>Country</InputLabel>
                <Controller
                    name="country"
                    render={({ field }) => (
                        <Select
                            {...field}
                            options={countryNames.map(i => { return { value: i, label: i } })}
                        />
                    )}
                    control={control}
                    defaultValue=""
                />
                <InputLabel>Zip/Postal Code</InputLabel>
                <Controller
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                        <TextField variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} fullWidth />
                    }
                    name="zip"
                    control={control}
                    defaultValue=""
                    className="materialUIInput"
                />
                <InputLabel>Input your GPA or equivalent results</InputLabel>
                <Controller
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                        <TextField variant="outlined" multiline rows={8} value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} fullWidth />
                    }
                    name="grades"
                    control={control}
                    defaultValue=""
                    className="materialUIInput"
                />
                <InputLabel>When would you like to start studying abroad?</InputLabel>
                <Controller
                    name="intake"
                    render={({ field }) => (
                        <Select
                            {...field}
                            options={[
                                { value: "january2023", label: "January 2023" },
                                { value: "september2023", label: "September 2023" },
                                { value: "january2024", label: "January 2024" },
                                { value: "september2024", label: "September 2024" },
                                { value: "january2025", label: "January 2025" },
                                { value: "september2025", label: "September 2025" }
                            ]}
                            className="dropdown"
                        />
                    )}
                    control={control}
                    defaultValue=""
                />
                <InputLabel>What do you want to study?</InputLabel>
                <Controller
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                        <TextField variant="outlined" multiline rows={8} value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} fullWidth />
                    }
                    name="studyInfo"
                    control={control}
                    defaultValue=""
                    className="materialUIInput"
                />
                <InputLabel>Nationality</InputLabel>
                <Controller
                    name="countryOfResidence"
                    render={({ field }) => (
                        <Select
                            {...field}
                            options={nationalities.map(i => { return { value: i, label: i } })}
                            className="dropdown"
                        />
                    )}
                    control={control}
                    defaultValue=""
                />
                <InputLabel>Tell us about yourself and what inspires you?</InputLabel>
                <Controller
                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                        <TextField variant="outlined" multiline rows={8} value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} fullWidth />
                    }
                    name="studentBio"
                    control={control}
                    defaultValue=""
                    className="materialUIInput"
                />


                <div className="social">
                    <Typography variant="h5">Social Profiles</Typography>
                    <Typography variant="body2">Please add your social media profile urls.</Typography>
                    <InputLabel>Facebook Account</InputLabel>
                    <Controller
                        render={({ field: { onChange, value }, fieldState: { error } }) =>
                            <TextField variant="outlined" value={value} onChange={onChange} error={errors.property === 'facebook'} helperText={errors.property === 'facebook' ? errors.message : null} fullWidth />
                        }
                        name="facebook"
                        control={control}
                        defaultValue=""
                        className="materialUIInput"
                    />

                    <InputLabel>Twitter</InputLabel>
                    <Controller
                        render={({ field: { onChange, value }, fieldState: { error } }) =>
                            <TextField variant="outlined" value={value} onChange={onChange} error={errors.property === 'twitter'} helperText={errors.property === 'twitter' ? errors.message : null} fullWidth />
                        }
                        name="twitter"
                        control={control}
                        defaultValue=""
                        className="materialUIInput"
                    />

                    <InputLabel>Instagram</InputLabel>
                    <Controller
                        render={({ field: { onChange, value }, fieldState: { error } }) =>
                            <TextField variant="outlined" value={value} error={errors.property === 'instagram'} helperText={errors.property === 'instagram' ? errors.message : null} fullWidth />
                        }
                        name="instagram"
                        control={control}
                        defaultValue=""
                        className="materialUIInput"
                    />

                    <InputLabel>LinkedIn</InputLabel>
                    <Controller
                        render={({ field: { onChange, value }, fieldState: { error } }) =>
                            <TextField variant="outlined" value={value} onChange={onChange} error={errors.property === 'linkedIn'} helperText={errors.property === 'linkedIn' ? errors.message : null} fullWidth />
                        }
                        name="linkedIn"
                        control={control}
                        defaultValue=""
                        className="materialUIInput"
                    />


                    {(!profile || profile?.status === 'PENDING') && (
                        <FormControlLabel
                            control={
                                <Controller
                                    name="acceptTsAndCs"
                                    render={({ fieldState: { error }, ...props }) => (
                                        <>
                                            <Checkbox
                                                checked={!!props.field.value}
                                                onChange={(e) => props.field.onChange(e.target.checked)}
                                                color="primary"
                                            />
                                            {error && (
                                                <Typography color="error" className="acceptTermsError" variant="caption">Please accept the Terms and Conditions before continuing</Typography>
                                            )}
                                        </>
                                    )}
                                    control={control}
                                    rules={{
                                        required: "Please accept Terms and Conditions before continuing",
                                    }}
                                />
                            }
                            label={<InputLabel>Accept our <a class="tsandcs" href="https://inspirus.io/terms-and-conditions">Terms and Conditions.</a></InputLabel>}
                        />
                    )}

                </div>

                <Divider />
                <div className="button-wrapper">
                    <Button type="submit" color="primary" variant="contained" fullWidth disabled={lockSubmit} >Save changes</Button>
                </div>
            </form>
        </div>
    );
}

export default Profile;
