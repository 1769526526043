import Keycloak from "keycloak-js";

import KeycloakConfig from "./keycloakConfig";

// Wraps keycloak object in a singleton and exposes certain properties
const KeycloakFactory = (function () {
  let instance;
  let tokens;

  async function refreshTokens() {
    const data = new URLSearchParams();
    data.append("client_id", "inspirus");
    data.append("grant_type", "refresh_token");
    data.append("refresh_token", tokens.refreshToken);

    try {
      const resp = await fetch(KeycloakConfig.tokenEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: data,
      });

      const respJson = await resp.json();

      if (respJson.error) throw respJson;

      tokens = { accessToken: respJson.access_token, refreshToken: respJson.refresh_token };
      return tokens;
    } catch (error) {
      if (error.error === "invalid_grant") {
        // refresh token has expired, redirect to login page with appended redirect uri to return to same page
        const loginRedirectUrl =
          `https://auth.inspirus.io/auth/` +
          `realms/inspirus` +
          `/protocol/openid-connect/auth?client_id=${KeycloakConfig.clientId}` +
          `&redirect_uri=${encodeURIComponent(window.location.href)}`;

        window.location.href = loginRedirectUrl;
      }
    }
  }

  function createInstanceFromToken(accessToken, idToken, refreshToken) {
    const keycloak = Keycloak(KeycloakConfig);

    return keycloak
      .init({ token: accessToken, idToken: idToken, refreshToken: refreshToken })
      .then(() => {
        tokens = {
          accessToken: keycloak.token,
          refreshToken: keycloak.refreshToken,
        };

        return keycloak;
      })
      .catch((e) => {
        throw new Error("unable to initialize authentication flow");
      });
  }

  function createInstance() {
    const keycloak = Keycloak(KeycloakConfig);

    return keycloak
      .init({ onLoad: KeycloakConfig.onLoad })
      .then(() => {
        keycloak.onTokenExpired = () => {
          keycloak.updateToken(30).catch((e) => {
            throw new Error("Unable to refresh token");
          });
        };

        tokens = {
          accessToken: keycloak.token,
          refreshToken: keycloak.refreshToken,
        };

        return keycloak;
      })
      .catch((e) => {
        throw new Error("unable to initialize authentication flow");
      });
  }

  return {
    initFromTokens: function (accessToken, idToken, refreshToken) {
      if (!instance) {
        instance = createInstanceFromToken(accessToken, idToken, refreshToken);
      }
      return instance;
    },
    init: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
    getUserInfo: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance.then((keycloak) => keycloak.loadUserInfo().then((info) => info));
    },
    logout: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance.then((keycloak) => keycloak.logout());
    },
    getTokens: function () {
      if (!instance) {
        instance = createInstance();
      }
      return tokens;
    },
    refreshTokens: async function () {
      if (!instance) {
        instance = createInstance();
      }
      await refreshTokens();
      return tokens;
    },
    handleRefreshTokens: async function () {
      if (instance) {
        instance = refreshTokens();
      }
      return tokens;
    },
    getRefreshToken: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance.then((keycloak) => keycloak.refreshToken);
    },
  };
})();

export default KeycloakFactory;
