import { Button, InputLabel, TextField, Typography } from '@material-ui/core';
import { ArrowBack, Check } from '@material-ui/icons';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import AssociationContext from '../../../context/Association';
import './student-progress.scss';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';
import { get, put } from '../../../services/api/baseApi';
import { useDispatch } from 'react-redux';
import { displayToast } from '../../../store/reducers/uiStatusReducer';

const styles = {
    groupButton: {
        padding: '6px 16px',
        border: '1px solid !important',
        marginRight: '16px',
        textTransform: 'capitalize',
        borderRadius: '10px !important'
    },
    selectedButton: {
        backgroundColor: '#4eadf2 !important',
        color: '#fff !important'
    },
    itemDisabled: {
        opacity: 0.4
    }
};

const StudentProgress = ({ classes }) => {
    const history = useHistory();
    const { application } = useContext(AssociationContext);
    const { association: {child} } = useContext(AssociationContext);
    const [currentStep, setCurrentStep] = useState(2);
    const [currentStatusValues, setCurrentStatusValues] = useState([]);
    const [applicationMethods, setApplicationMethods] = useState([]);
    const [currentStatusSelected, setCurrentStatusSelected] = useState();
    const [applyMethodSelected, setApplyMethodSelected] = useState();
    const dispatch = useDispatch();

    const getCurrentStatusValues = useCallback(() => {
        const getCurrentStatusValues = async () => {
            try {
                const response = await get(`${process.env.REACT_APP_INSPIRUS_API_URL}/constant/application-current-status`);
                const body = await response.json();
                if (response.ok) {
                    setCurrentStatusValues(body);
                } 
            } catch(error) {
                console.log(error);
            } 
        };
        getCurrentStatusValues();
    }, []);

   const getApplicationMethods = useCallback(() => {
        const getApplicationMethods = async () => {
            try {
                const response = await get(`${process.env.REACT_APP_INSPIRUS_API_URL}/constant/application-method`);
                const body = await response.json();
                if (response.ok) {
                    setApplicationMethods(body);
                } 
            } catch(error) {
                console.log(error);
            } 
        };
        getApplicationMethods();
    }, []);

    const getStatusValues = useCallback(() => {
        const fetchStatusValues = async () => {
            try {
                const response = await get(`${process.env.REACT_APP_INSPIRUS_API_URL}/constant/application-status`);
                const body = await response.json();
                if (response.ok) {
                    setCurrentStep(body[application.status].position);
                } 
            } catch(error) {
                console.log(error);
            } 
        };
        fetchStatusValues();
    }, [application?.status]);

    useEffect(() => {
        getStatusValues();
        getCurrentStatusValues();
        getApplicationMethods();
    }, [getStatusValues, getCurrentStatusValues, getApplicationMethods]);


    const defaultValues = {
        acceptedOffer: application?.acceptedOffer,
        applicationDate: application?.applicationDate,
        applicationFeePaid: application?.applicationFeePaid,
        studentStatus: application?.studentStatus,
        paidTuition: application?.paidTuition,
        applyMethod: application?.applyMethod
    };

    const { control, setValue, handleSubmit } = useForm({defaultValues});

    useEffect(() => {
        setCurrentStatusSelected({ value: application?.currentStatus, label: currentStatusValues[application?.currentStatus]?.text });
    }, [currentStatusValues, application?.currentStatus]);

    useEffect(() => {
        setApplyMethodSelected({ value: application?.applyMethod, label: applicationMethods[application?.applyMethod]?.text });
    }, [applicationMethods, application?.applyMethod]);

    const updateApplication = useCallback(async (id, data) => {
        try {
            const response = await put(`${process.env.REACT_APP_INSPIRUS_API_URL}/application/${id}`, data);
            if (response.ok) {
                dispatch(displayToast('Application progress updated', 'success'));
            } else {
                dispatch(displayToast('Failed to update application progress', 'error'));
            }
        } catch(error) {
            dispatch(displayToast('Failed to update application progress', 'error'));
            console.log(error);
        }
    }, [dispatch]);

    const onSubmit = (data) => {
        // let updatedApplicationData = { ...application, ...data};
        // Set new status
        // const newStatus = Object.entries(statusValues).find(status => status[1].position === (currentStep))[0];
        // updatedApplicationData = { ...updatedApplicationData, status: newStatus };
        // const { id, ...updateData } = updatedApplicationData;
        const dataToSubmit = {
            ...data,
            currentStatus: data?.currentStatus?.value,
            applyMethod: data?.applyMethod?.value
        };

        updateApplication(application.id, dataToSubmit);
    }

    const setValueAndIterateStep = (fieldName, value, newStep) => {
        setValue(fieldName, value);
        if (currentStep < newStep) {
            setCurrentStep(newStep);
        }
    }

    const renderTimelineDot = (step) => (
        <>
            <TimelineSeparator>
                {currentStep > step && (
                    <TimelineDot color="primary">
                        <Check />
                    </TimelineDot>
                )}
                {currentStep <= step && (
                    <TimelineDot variant="outlined" />
                )}
            <TimelineConnector />
            </TimelineSeparator>
        </>    
    );

    return (
        <div className="progress page-content">
            <div className="go-back" onClick={() => history.goBack()}>
                <ArrowBack/>
                <Typography variant="body2">Back to Applications</Typography>
            </div>
            <Typography variant="h4" className="bold">{child?.givenName} {child?.familyName}'s Application Progress</Typography>
            <Typography variant="body2" className="appliedTo">Applied to {application?.courseName?.slice(0, -1)} at {application?.universityName}.</Typography>
            <Typography variant="body2">Reference: {application?.applicationRef}</Typography>
            <div className="progress-timeline">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Timeline align="left">
                        <TimelineItem className={currentStep < 1 ? classes.itemDisabled : ''}>
                            {renderTimelineDot(0)}
                            <TimelineContent>
                                <InputLabel>Student has started their application?</InputLabel>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem className={currentStep < 1  ? classes.itemDisabled : ''}>
                            {renderTimelineDot(1)}
                            <TimelineContent>
                                <InputLabel>Date of application</InputLabel>
                                <Controller
                                    render={({ field: { onChange, value }, fieldState: { error } }) =>
                                        <TextField inputProps={{ max: new Date().toISOString().split("T")[0]}}disabled={currentStep < 1 || application?.sendApplicationEmail} id="date" type="date" variant="outlined" value={value} onChange={(event) => setValueAndIterateStep("applicationDate", event.target.value, 2)} error={!!error} helperText={error ? error.message : null} fullWidth />
                                    }
                                    name="applicationDate"
                                    control={control}
                                    defaultValue=""
                                    className="materialUIInput"
                                />
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem className={currentStep < 2 ? classes.itemDisabled : ''}>
                            {renderTimelineDot(2)}
                            <TimelineContent>
                                <InputLabel>Has the student paid their application fee?</InputLabel>
                                <Controller
                                    render={({ field: { value }, fieldState: { error } }) =>
                                        <ToggleButtonGroup
                                            value={value}
                                            onChange={(event, newValue) => setValueAndIterateStep('applicationFeePaid', newValue, 3)}
                                            exclusive
                                            disabled={currentStep < 2 || application?.sendApplicationEmail}
                                        >
                                            <ToggleButton className={`${classes.groupButton} ${value === 'YES' ? classes.selectedButton : ''}`} value="YES" disabled={currentStep < 2 || application?.sendApplicationEmail}>Yes</ToggleButton>
                                            <ToggleButton className={`${classes.groupButton} ${value === 'NO' ? classes.selectedButton : ''}`} value="NO" disabled={currentStep < 2 || application?.sendApplicationEmail}>No</ToggleButton>
                                            <ToggleButton className={`${classes.groupButton} ${value === 'WAIVED' ? classes.selectedButton : ''}`} value="WAIVED" disabled={currentStep < 2 || application?.sendApplicationEmail}>Waived</ToggleButton>
                                        </ToggleButtonGroup>  
                                    }
                                    name="applicationFeePaid"
                                    control={control}
                                    defaultValue=""
                                    className="materialUIInput"
                                />
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem className={currentStep < 3 ? classes.itemDisabled : ''}>
                            {renderTimelineDot(3)}
                            <TimelineContent>
                                <InputLabel>Please input the students current status</InputLabel>
                                <Controller
                                    name="currentStatus"
                                    render={({ field: { value } }) => (
                                    <Select
                                        options={Object.entries(currentStatusValues).map(([k,v]) => {return {value: k, label: v.text}})}
                                        className="dropdown"
                                        onChange={event => {
                                            setCurrentStatusSelected(event);
                                            setValueAndIterateStep("currentStatus", event, 4)
                                        }}
                                        isDisabled={currentStep < 3 || application?.sendApplicationEmail}
                                        value={currentStatusSelected}
                                    />
                                    )}
                                    control={control}
                                    defaultValue=""
                                />
                                <InputLabel>How did they apply?</InputLabel>
                                <Controller
                                    name="applyMethod"
                                    render={({ field: {value} }) => (
                                    <Select
                                        options={Object.entries(applicationMethods).map(([k,v]) => {return {value: k, label: v.text}})}
                                        className="dropdown"
                                        onChange={event => {
                                            setApplyMethodSelected(event);
                                            setValueAndIterateStep("applyMethod", event, 4);
                                        }}
                                        isDisabled={currentStep < 3 || application?.sendApplicationEmail}
                                        value={applyMethodSelected}
                                    />
                                    )}
                                    control={control}
                                    defaultValue=""
                                />
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem className={currentStep < 4 ? classes.itemDisabled : ''}>
                            {renderTimelineDot(4)}
                            <TimelineContent>
                                <InputLabel>Send the application email to student?</InputLabel>
                                <Controller
                                    render={({ field: { value }, fieldState: { error } }) =>
                                        <ToggleButtonGroup
                                            value={value}
                                            onChange={(event, newValue) => setValueAndIterateStep('sendApplicationEmail', newValue, 5)}
                                            exclusive
                                            disabled={currentStep < 5}
                                        >
                                            <ToggleButton className={`${classes.groupButton}`} value="true" disabled={currentStep < 2 || application?.sendApplicationEmail}>Send</ToggleButton>
                                        </ToggleButtonGroup>  
                                    }
                                    name="sendApplicationEmail"
                                    control={control}
                                    defaultValue=""
                                    className="materialUIInput"
                                />
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem className={currentStep < 5 ? classes.itemDisabled : ''}>
                            {renderTimelineDot(5)}
                            <TimelineContent>
                                <InputLabel>Has the student accepted their application offer?</InputLabel>
                                <Controller
                                    render={({ field: { value }, fieldState: { error } }) =>
                                        <ToggleButtonGroup
                                            value={value}
                                            onChange={(event, newValue) => setValueAndIterateStep('acceptedOffer', newValue, 6)}
                                            exclusive
                                            disabled={currentStep < 5}
                                        >
                                            <ToggleButton className={`${classes.groupButton} ${value === 'YES' ? classes.selectedButton : ''}`} value="YES">Yes</ToggleButton>
                                            <ToggleButton className={`${classes.groupButton} ${value === 'NO' ? classes.selectedButton : ''}`} value="NO">No</ToggleButton>
                                        </ToggleButtonGroup>
                                    }
                                    name="acceptedOffer"
                                    control={control}
                                    className="materialUIInput"
                                />
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem className={currentStep < 6 ? classes.itemDisabled : ''}>
                            {renderTimelineDot(6)}
                            <TimelineContent>
                                <InputLabel>Has the student paid their tuition fees?</InputLabel>
                                <Controller
                                    render={({ field: { value }, fieldState: { error } }) =>
                                        <ToggleButtonGroup
                                            value={value}
                                            onChange={(event, newValue) => setValueAndIterateStep('paidTuition', newValue, 7)}
                                            exclusive
                                            disabled={currentStep < 6}
                                        >
                                            <ToggleButton className={`${classes.groupButton} ${value === 'YES' ? classes.selectedButton : ''}`} value="YES">Yes</ToggleButton>
                                            <ToggleButton className={`${classes.groupButton} ${value === 'NO' ? classes.selectedButton : ''}`} value="NO">No</ToggleButton>
                                        </ToggleButtonGroup>
                                    }
                                    name="paidTuition"
                                    control={control}
                                    defaultValue=""
                                    className="materialUIInput"
                                />
                            </TimelineContent>
                        </TimelineItem>
                        {/* <TimelineItem className={currentStep < 7 ? classes.itemDisabled : ''}>
                            {renderTimelineDot(7)}
                            <TimelineContent>
                                <InputLabel>Inspirus Commision Payment</InputLabel>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem className={currentStep < 8 ? classes.itemDisabled : ''}>
                            {renderTimelineDot(8)}
                            <TimelineContent>
                                <InputLabel>Inspirus Fee Received</InputLabel>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem className={currentStep < 9 ? classes.itemDisabled : ''}>
                            {renderTimelineDot(9)}
                            <TimelineContent>
                                <InputLabel>Agent Received Funds</InputLabel>
                            </TimelineContent>
                        </TimelineItem> */}
                        <TimelineItem className={currentStep < 6 ? classes.itemDisabled : ''}>
                            {renderTimelineDot(6)}
                            <TimelineContent>
                                <InputLabel>Student Journey Complete</InputLabel>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>
                    <Button type="submit" variant="contained" color="primary">Save Changes</Button>
                </form>
            </div>
        </div>
    );
}

export default withStyles(styles)(StudentProgress);
