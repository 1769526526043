import { Box, Button, Tabs, Tab, Typography } from '@material-ui/core';
import React, { useEffect, useState, useCallback } from 'react';
import './landing-page.scss';
import Profile from '../Profile'
import { withStyles } from '@material-ui/styles';
import DocumentUpload from '../DocumentUpload'
import Dashboard from '../Dashboard';
import Select from "react-select";
import AccountSettings from '../../AccountSettings';
import Shortlisting from '../../Shortlisting';
import { studentLandingPageDropdown, studentLandingPageRestrictedDropdown, studentLandingPageNav } from '../../../constants/constants';
import { useHistory } from 'react-router';
import { ArrowBack } from '@material-ui/icons';
import { get, patch } from "../../../services/api/baseApi";
import DefaultImage from '../../../assets/default-profile.svg';

const styles = {
    error: {
        backgroundColor: '#F55D4F',
        color: "#fff"
    },
    buttonGreen: {
        backgroundColor: 'rgb(56, 142, 60)',
        color: '#fff',
    }
};

function TabPanel({ children, value, index }) {
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`student-tabpanel-${index}`}
        aria-labelledby={`student-tab-${index}`}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
                {children}
            </Box>
        )}
        </div>
    );
} 

const LandingPage = ({profile, fetchUser, pageTag, setPageTag, agentView, classes}) => {

    const [value, setValue] = useState(0);
    const [associationData, setAssociationData] = useState([]);
    const history = useHistory();

    const getAssociations = useCallback(() => {
        const fetchUploads = async () => {
            try {
                const response = await get(`${process.env.REACT_APP_INSPIRUS_API_URL}/association`);
                const body = await response.json();
                if (response.ok) {
                    setAssociationData(body); 
                } 
            } catch(error) {
                console.log(error);
            } 
        };
        fetchUploads();
    }, []);

    const approveAssociation = useCallback((approved, id) => {
        const approve = async () => {
            try {
                const response = await patch(`${process.env.REACT_APP_INSPIRUS_API_URL}/association/${id}/status?accepted=${approved}`);
                if (response.ok) {
                    getAssociations();
                } 
            } catch(error) {    
                console.log(error);
            } 
        };
        approve();
    }, [getAssociations]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setPageTag(studentLandingPageNav[newValue]);
    };

    const handleDropdownChange = (event) => {
        setValue(event.value);
    };

    useEffect(() => {
        setValue(pageTag ? studentLandingPageNav.indexOf(pageTag) : 0)
        getAssociations();
    }, [pageTag, getAssociations]);

    return (
        <div className="home page-content">
        {agentView && (
            <div className="go-back" onClick={() => history.push("/")}>
                <ArrowBack/>
                <Typography variant="body2">Back to Dashboard</Typography>
            </div>
        )}
        <Select
                options={associationData.length > 0 ? studentLandingPageDropdown : studentLandingPageRestrictedDropdown}
                className="nav-dropdown"
                onChange={handleDropdownChange}
                value={associationData.length > 0 ? studentLandingPageDropdown[value] : studentLandingPageRestrictedDropdown[value]}
                styles={{
                    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                    menu: provided => ({ ...provided, zIndex: 9999 })
                }}
            />
            <Box sx={{ display: 'flex'}} className="home-content">
                <Box sx={{ width: '200px' }} className="sideNav">
                    <Tabs value={value} orientation="vertical" onChange={handleChange} aria-label="student application tabs example">
                        <Tab label="Profile" value={0} />
                        {associationData.length > 0 && (
                            <Tab label="Documents Upload" value={1} />
                        )}
                        <Tab label="Shortlisting" value={2} />
                        {associationData.length > 0 && (
                            <Tab label="Dashboard" value={3} />
                        )}
                        {!agentView && (
                            <Tab label="Account Settings" value={4} />
                        )}
                    </Tabs>
                </Box>
                <Box className="tabs-parent"sx={{ padding: '0' }}>
                    {agentView && (
                        <Typography className="agent-message" variant="subtitle">You are editing this profile as an agent</Typography>
                    )}
                    <TabPanel value={value} index={0}>
                        <Profile profile={profile} fetchUser={fetchUser} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <DocumentUpload agentView={agentView} profile={profile} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Shortlisting profile={profile} agentView={agentView}/>
                    </TabPanel>
                    
                    <TabPanel value={value} index={3}>
                        <Dashboard agentView={agentView} association={associationData.length > 0 && associationData?.filter(association => association.child.id === profile.id)[0]}/>
                    </TabPanel>

                    {!agentView && (
                        <TabPanel value={value} index={4}>
                            <AccountSettings profile={profile} fetchUser={fetchUser} />
                        </TabPanel>
                    )}
                </Box>
                {!agentView && associationData?.length > 0 && associationData[0]?.parent && (
                    <div className="agent-tile">
                        <div className="user-avatar-wrapper">
                            <div className="user-avatar">
                                {(
                                    <img src={associationData[0]?.parent?.avatar || DefaultImage} accept="image/*" alt="Profile" />
                                )}
                            </div>
                        </div>
                        <Typography variant="h6">{associationData[0]?.parent?.givenName} {associationData[0]?.parent?.familyName}</Typography>
                        {associationData[0]?.status === 'PENDING' && (
                            <div className="button-container">
                                <Button type="button" className={classes.buttonGreen} variant="contained" onClick={() => approveAssociation(true, associationData[0].id)}>Accept</Button>
                                <Button type="button" className={classes.error} variant="contained" onClick={() => approveAssociation(false, associationData[0].id)}>Decline</Button>
                            </div>
                            )
                        }
                        {associationData[0]?.status === 'ACTIVE' && (
                            <>
                                <Typography variant="body" className="hoverClick" color="primary" onClick={() => window.location = `mailto:${associationData[0]?.parent?.email}`}>{associationData[0]?.parent?.email}</Typography>
                                <br />
                                <br />
                                <Typography variant="body">You are associated with this agent, they will assist you with processing this application.</Typography>
                            </>
                            )
                        }
                    </div>
                )}
            </Box>
        </div>
    );
}

export default withStyles(styles)(LandingPage);