import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Settings from '@material-ui/icons/Settings';
import ExitToApp from '@material-ui/icons/ExitToApp';
import './user-dropdown.scss';
import { Typography } from '@material-ui/core';
import DefaultImage from '../../../assets/default-profile.svg';
import KeycloakFactory from '../../../services/auth/keycloak';
import { useHistory } from 'react-router-dom';

const UserDropdown = ({ additionalType, username, avatar, setPageTag, isHidden }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (pageTag) => {
        setAnchorEl(null);
        if(pageTag) setPageTag(pageTag);
    };

    const handleNavigate = (uri) => {
        setAnchorEl(null);
        history.push(uri);
    };

    const history = useHistory();

    return (
        <div className="user-dropdown">
            <Button
                className={`user-menu-button${isHidden ? ' hidden' : ''}`}
                aria-controls="user-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <span className="user-dropdown-username">{username}</span>
                <ExpandMore />
                <div className="user-avatar">
                    {(
                        <img src={avatar || DefaultImage} accept="image/*" alt="Profile" />
                    )}
                </div>
            </Button>
            <Menu
                id="user-menu"
                className="user-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose('')}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                getContentAnchorEl={null}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => additionalType?.includes("AGENT") ? handleNavigate("/agentProfile") : handleClose('profile')}><AccountCircle /><Typography variant="body2">Profile</Typography></MenuItem>
                <MenuItem onClick={() => additionalType?.includes("AGENT") ? handleNavigate("/accountSettings") : handleClose('account-settings')}><Settings /><Typography variant="body2">Account Settings</Typography></MenuItem>
                <MenuItem onClick={() => KeycloakFactory.logout()}><ExitToApp /><Typography variant="body2">Logout</Typography></MenuItem>
            </Menu>
        </div>
    );
}

export default UserDropdown;
